<template>
    <v-container fluid class="pa-0">
        <h2 class="mt-5 mr-5"><span>{{$t('chooseAReturnFlight')}}</span></h2>
        <template v-for="(flight, index) in flights">
            <v-card 
                @click="selectFlight(flight)"
                :key="'f-' + index"
                class="my-5"
            >
                <v-list>
                    <v-list-item>
                    <v-list-item-avatar>
                        <v-icon
                            v-if="flight.uuid === selectedFlight.uuid"
                            color="primary"
                            size="28"
                        >
                            mdi-checkbox-marked
                        </v-icon> 
                        <v-icon
                            v-else
                            color="primary"
                            size="28"
                        >
                            mdi-checkbox-blank-outline
                        </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title><span class="font-weight-bold text-h6">{{departureDate(flight)}}</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item>
                        <v-list-item-content>
                            <v-row>
                                <v-col class="text-center" cols="2">
                                    <div v-if="flight.departureAirport"> 
                                        <v-col v-if="flight.departureAirport.name" class="pa-0">
                                            <span>{{ flight.departureAirport.name }}</span>
                                        </v-col>
                                        <v-col v-if="flight.departureAirport.iata" class="pa-0">
                                            <span  style="font-size: x-large; font-weight: bold;">{{ flight.departureAirport.iata }}</span>
                                        </v-col >
                                        <v-col class="pa-0" >
                                            <span>{{departureTime(flight)}}</span> 
                                        </v-col>
                                    </div>
                                    <div v-else>
                                        <span>{{$t('missingAirportInfo')}}</span>
                                    </div>
                                </v-col>
                                <v-col class="text-center" cols="8">
                                    <v-col class="pa-0">
                                        <v-icon large color="primary" style="transform:rotate(45deg)">mdi-airplane</v-icon>
                                    </v-col>
                                    <v-col class="pa-0" >{{ flightDuration(flight) }}</v-col>
                                    <v-col class="pa-0"><span class="primary--text font-weight-bold">{{flight.number}}</span></v-col>
                                </v-col>
                                <v-col class="text-center" cols="2">
                                    <div v-if="flight.arrivalAirport"> 
                                        <v-col v-if="flight.arrivalAirport.name" class="pa-0">
                                            <span>{{ flight.arrivalAirport.name }}</span>
                                        </v-col>
                                        <v-col v-if="flight.arrivalAirport.iata" class="pa-0">
                                            <span  style="font-size: x-large; font-weight: bold;">{{ flight.arrivalAirport.iata }}</span>
                                        </v-col >
                                        <v-col class="pa-0" >
                                            <span>{{arrivalTime(flight)}}</span> 
                                        </v-col>
                                    </div>
                                    <div v-else>
                                        <span>{{$t('missingAirportInfo')}}</span>
                                    </div>
                                </v-col>
                            </v-row>
                  
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </template>
    </v-container>
</template>

<script>

import moment from "moment";

export default ({
    name: "ReturnFlight", 
    namespace: 'fflight_return', 
    components: {
        
    },
    data() {
        return {
            passengerCount: 4,
            flights: [
    {               number: "EJ785",
                    uuid: 8,
                    departure: 1683995400000, 
                    price: {
                        amount: 22300, 
                        currency_code: 'ISK'
                    }, 
                    arrival: 1683995700000, 
                    departureAirport: {
                        name: 'Zabref', 
                        iata: 'ZAG', 
                    }, 
                    arrivalAirport: {
                        name: 'Keflavík', 
                        iata: 'KEF'
                    }
                }
            ], 
            selectedFlight: null
        }
    },
    methods: {
        selectFlight(value) {
            this.selectedFlight = value
        },
        departureDate(flight) {
            const timestamp = new Date(flight.departure)
            const weekday = timestamp.toLocaleDateString('default', {weekday: 'long'})
            const month = timestamp.toLocaleDateString('default', {month: 'long'})
            const monthday = timestamp.getDate()
            const year = timestamp.getFullYear()
            return this.$t(weekday) +  ', ' + this.$t(month) + ' ' + monthday +  ', ' + year
        },
        departureTime(flight) {
            const timestamp = new Date(flight.departure)
            return moment(timestamp).format('HH:mm')
        },
        arrivalTime(flight) {
            const timestamp = new Date(flight.arrival)
            return moment(timestamp).format('HH:mm')
        },
        totalPrice(flight) {
            const totalPrice = Object.assign({}, flight.price)
            totalPrice.amount *= this.passengerCount
            return totalPrice
        }, 
        flightDuration(flight) {
            if(!flight.arrival || !flight.departure ) {
                return this.$t('invalidDates')
            }
            let durationInMillis = flight.arrival - flight.departure
            const hours = Math.floor(durationInMillis / (1000 * 60 * 60))
            durationInMillis = durationInMillis - (hours * 1000 * 60 * 60) 
            const minutes = Math.floor(durationInMillis /  (1000 * 60))
            return hours + 'h ' + minutes + 'm'   
        }
    }, 
    mounted() {
        this.selectedFlight = this.flights[0]
    }

})
</script>

<style>

</style>
