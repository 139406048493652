<template>
	<v-expansion-panel :value="paymentMethodInstance.uuid" href="#10" :key="paymentMethodInstance.uuid">
		<v-expansion-panel-header>
			<v-list-item class="pa-0 ma-0" three-line>
				<v-list-item-avatar tile :rounded="true" size="48" class="rounded">
					<v-icon v-if="paymentMethodInstance.icon === null" large color="grey lighten-1">mdi-information</v-icon>
					<v-img v-else large :src="paymentMethodInstance.icon"/>
				</v-list-item-avatar>
				<v-list-item-content>
          <v-list-item-title>{{ $t(paymentMethodInstance.title) }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t(paymentMethodInstance.description) }}</v-list-item-subtitle>
          <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>
			</v-list-item>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<v-alert
			v-if="paymentMethodInstance !== null && paymentMethodInstance !== undefined && paymentMethodInstance.alertMessage !== null && paymentMethodInstance.alertMessage !== undefined "
			type="warning"
			density="compact"
		>{{ paymentMethodInstance.alertMessage }}</v-alert>
			<div v-if="paymentMethodInstance.uiFields">
				<v-row>
					<template v-for="(uiField, index) in paymentMethodInstance.uiFields">
						<v-col class="mt-0 mb-0 pt-0 pb-0" :key="uiField.key" :cols="uiField.cols">
							<ui-field v-model="paymentMethodInstance.uiFields[index]" :instance="paymentMethodInstance"/>
						</v-col>
					</template>
				</v-row>
			</div>
			<div v-else>
				No additional information required at this time.
			</div>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>

import uiField from "./uiField";

export default {
	name: 'PaymentMethodInstance',
	props: ['paymentMethodInstance'],
	components: {
		uiField
	},
	data: () => ({}),
	computed: {
		paymentMethod: function () {
			// eslint-disable-next-line no-console
			return this.$store.getters.getPaymentMethod(this.paymentMethodInstance.namespace)
		}
	}
};
</script>
