<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col align="center" cols="12">
        <CartOld :order="order"></CartOld>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import CartOld from "../components/Cart-Old";

  export default {
    name: 'cart',
    components: {
      CartOld
    },
    computed: {
      order: function() {
        return this.$store.state.order
      },
      channelUUID: {
        set: function(newValue) {
          this.$store.commit('updateChannelUUID', newValue)
        },
        get: function() {
          return this.$store.state.channelUUID
        }
      }
    },
    created() {
      this.channelUUID = this.$route.params.channel
    }
  }

</script>
