<template>
	<div class="mt-2">
		<PendingPayment v-if="showPendingPayment"></PendingPayment>
		<v-expansion-panels v-else v-model="selectedPaymentMethodInstance" mandatory>
			<template v-for="paymentMethodInstance in paymentMethodInstances">
        <PaymentMethodInstance
          v-show="!isAllowedDependentPayments || (isAllowedDependentPayments && !namespacesWhitelistedForDependentPayment.includes(paymentMethodInstance.namespace))"
          :key="paymentMethodInstance.uuid"
          :payment-method-instance="paymentMethodInstance"
        />
      </template>
		</v-expansion-panels>
	</div>
</template>

<script>

import PaymentMethodInstance from './PaymentMethodInstance'
import PendingPayment from "@/components/PendingPayment";

export default {
	name: 'PaymentCheckoutSectionInstance',
	props: ['checkoutSectionInstance'],
	components: {
		PendingPayment,
		PaymentMethodInstance
	},
	computed: {
    organization() {
      return this.$store.state.organization
    },
    isAllowedDependentPayments() {
      if (typeof this.organization !== 'undefined' && this.organization !== null) {
        return this.organization.uuid === this.$store.state.hvammsvik || this.organization.uuid === '32036a02-fd37-4044-bbb1-e55970e4531f'
      }
      return false
    },
    showPendingPayment() {
      return this.$store.getters.showPaymentPending
    },
    paymentMethodInstances() {
      return this.$store.state.paymentMethodInstances
    },
    namespacesWhitelistedForDependentPayment() {
      return this.$store.state.namespacesWhitelistedForDependentPayment
    },
    selectedPaymentMethodInstance: {
      set(value) {
        this.$store.commit('updateSelectedPaymentMethodInstanceIndex', value)
      },
      get() {
        return this.$store.state.selectedPaymentMethodInstance
      }
    }
  }
}
</script>