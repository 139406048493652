<template>
    <v-container fluid class="py-1">
        <v-row dense>
            <v-col cols="auto" style="position: absolute; right: 0; top: 0;">
                <v-btn small icon @click="getSubscriptions" elevation="1" :disabled="gettingOrderSubscriptions">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" style="font-weight: 500; font-size: 16px;">
                {{ $t('order.subscriptions.title') }} <span v-if="gettingOrderSubscriptions" style="font-weight: 400; font-size: 12px;">{{
                    $t('order.subscriptions.pleaseWait')
                }}</span>
            </v-col>
        </v-row>
        <v-row dense v-if="gettingOrderSubscriptions">
            <v-col cols="12">
                <v-row dense align="center">
                    <template v-for="i in subscriptionLineItems.length">
                        <v-col cols="12" :key="i">
                            <v-skeleton-loader type="list-item-avatar-two-line" tile></v-skeleton-loader>
                        </v-col>
                    </template>
                </v-row>
            </v-col>
        </v-row>
        <v-row dense v-else justify="center">
            <v-col cols="auto" v-if="Array.isArray(subscriptions) && subscriptions.length < 1">
                {{ $t('order.subscriptions.none') }}
            </v-col>
            <template v-for="subscription in subscriptions">
                <v-col cols="12" :key="subscription.uuid">
                    <v-card>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar tile style="border-radius: 4px;" v-if="$vuetify.breakpoint.width > 320">
                                    <v-img :src="subscriptionImage(subscription)" class="grey" style="border-radius: 4px;"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ subscription.label }} {{ (subscription.quantity && subscription.quantity > 1) ? 'x' + subscription.quantity : '' }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ $t('order.subscriptions.nextPayment') }}: {{ subscriptionNextPayment(subscription) }}
                                    </v-list-item-subtitle>
                                    <v-list-item-action v-if="$vuetify.breakpoint.width > 460">
                                        <v-btn color="green" @click="fetchTicket(subscription)" depressed>Sækja passa</v-btn>
                                    </v-list-item-action>
                                </v-list-item-content>
                            </v-list-item>
                            <v-btn block color="green" @click="fetchTicket(subscription)" v-if="$vuetify.breakpoint.width <= 460">Sækja passa</v-btn>
                        </v-list>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "OrderSubscriptions",
    props: {
        subscriptionLineItems: Array
    },
    data() {
        return {
            // gettingOrderSubscriptions: false,
            // subscriptions: []
        }
    },
    mounted() {
        this.getSubscriptions()
    },
    computed: {
        gettingOrderSubscriptions: {
            get() {
                return this.$store.getters.gettingOrderSubscriptions
            },
            set(status) {
                this.$store.commit('updateGettingOrderSubscriptions', status)
            }
        },
        primaryColor() {
            return this.$store.getters.primaryColor
        },
        subscriptions() {
            return this.$store.getters.orderSubscriptions
        },
        organizationUUID() {
            return this.$store.state.organizationUUID
        }
    },
    methods: {
        getSubscriptions() {
            this.gettingOrderSubscriptions = true
            this.$store.dispatch('getOrderSubscriptions', 0)
        },
        subscriptionImage(subscription) {
            if (subscription !== null && subscription !== undefined) {
                if (subscription.item !== null && subscription.item !== undefined) {
                    if (Array.isArray(subscription.item.images)) {
                        const firstImage = subscription.item.images[0]
                        if (firstImage.file !== null && firstImage.file !== undefined) {
                            if (firstImage.file.sources !== null && firstImage.file.sources !== undefined) {
                                if (firstImage.file.sources.medium !== null && firstImage.file.sources.medium !== undefined) {
                                    return firstImage.file.sources.medium
                                }
                            }
                        }
                    }
                }
            }
            return ''
        },
        fetchTicket(booking) {
            if (this.organizationUUID === 'c9632662-ce96-4261-91ee-eef98f5e9223') {
                if (booking.externalDistributionUrl !== null && booking.externalDistributionUrl !== undefined) {
                    open(booking.externalDistributionUrl, '_blank')
                } else {
                    return
                }
            }
            open('https://subscription.salescloud.is?uuid=' + booking.uuid + '&organization=' + this.organizationUUID, '_blank')
        },
        subscriptionNextPayment(subscription) {
            if (subscription !== null && subscription !== undefined) {
                if (subscription.nextPayment !== null && subscription.nextPayment !== undefined) {
                    let paymentDate = new Date(subscription.nextPayment).toISOString()
                    return paymentDate.substr(0, 10)
                }
            }
            return ''
        }
    }
}
</script>

<style scoped>

</style>
