<template>
    <v-container fluid class="pa-0" style="height: 100%">
        <h2 class="py-5 pr-5"><span>Hvernig má bjóða þér að greiða?</span></h2>
            <v-card class="ma-4" @click="console.log('clicked')">
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-center primary--text font-weight-bold">Greiða heildarupphæð</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
            <v-card class="ma-4"  @click="console.log('clicked')">
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-center primary--text font-weight-bold">Greiða staðfestingargjald</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
            <v-card class="ma-4"  @click="console.log('clicked')">
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-center primary--text font-weight-bold">Dreifa greiðslu</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>

    </v-container>
</template>

<script>

export default ({
    name: "PaymentPlan", 
    namespace: 'payment_plan', 
    components: {
        
    },
    data() {
        return {
            passengerCount: 4
        }
    },
    computed: {
    },
    methods: {
        selectInsurance(value) {
            console.log(value)
            
        }, 
        deselectInsurance(value) {
            console.log(value)
        }
    }, 

})
</script>

<style>

</style>
