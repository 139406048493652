<template>
  <tr>
    <td class="text-left"> <v-btn icon color="red" :disabled="!canDeleteLineItem" :loading="isDeletingLineItem" @click="deleteLineItem"> <v-icon>mdi-close</v-icon> </v-btn> </td>
    <td class="text-left"> {{ lineItem.line_item_label }} </td>
    <td class="text-left">
      {{quantity}}
    </td>
    <td class="text-right"> {{ lineItem.unitPrice | formattedPrice }} </td>
    <td class="text-right"> {{ lineItem.totalPrice | formattedPrice }} </td>
  </tr>
</template>

<script>
export default {
  name: 'CartItem',
  props: ['lineItem'],
  data: () => ({

  }),
  methods: {
    canDeleteLineItem: function(lineItem) {
      return this.$store.getters.canDeleteLineItem(lineItem)
    },
    deleteLineItem: function() {
      this.$store.dispatch('deleteLineItem', this.lineItem)
    }
  },
  computed: {
    quantity() {
      return Math.floor(parseFloat(this.lineItem.quantity))
    },
    isDeletingLineItem: function() {
      return this.$store.state.deletingLineItem
    }
  }
};
</script>
