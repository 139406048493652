<template>
  <v-container fluid class="pa-0">
    <h2 class="mt-5 mr-5"><span>{{$t('accommodations')}}</span></h2>
    <template v-for="(accommodation, index) in accommodations">
        <v-list-item  :key="index">
            <v-list-item-content>
                <v-list-item-title>
                {{ accommodation.title}}
                </v-list-item-title>
                <v-list-item-subtitle v-if="accommodation.description">
                {{ accommodation.description.value }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action style="min-width: 150px;">
                <v-row>
                    <v-col cols="7" class="text-center pt-5"><v-list-item-action-text style="font-size:medium; font-weight: bold">{{ totalPrice(accommodation.price, accommodation.price) | formattedPrice }}</v-list-item-action-text></v-col>
                    <v-col cols="5">        <v-btn width="75px" v-if="selectedVariation && selectedVariation.uuid === accommodation.uuid" color="success" depressed>
                    <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn v-else width="75px" color="primary" depressed @click="selectedVariation = accommodation">
                    {{$t('select')}}
                </v-btn></v-col>
                </v-row>
            </v-list-item-action>
        </v-list-item>
        <v-divider :key="'d' + index"/>
    </template>
  </v-container>
</template>

<script>

export default {
  name: "Accommodations",
  namespace: 'accommodation',
  components: {
  },
  data() {
    return {
      selectedVariation: null,
    }
  },
  computed: {
    accommodationsPerCategory() {
        const accommodationsPerCategory = {}

        for(let i in this.accommodations) {
            const accommodation = this.accommodations[i]
            if(accommodation.categories !== null && accommodation.categories !== undefined && accommodation.categories.length > 0) {
                for(let n in accommodation.categories) {
                    const category = accommodation.categories[n]
                    accommodationsPerCategory[category.uuid][accommodation.uuid] = accommodation
                }
            }
        }

        return accommodationsPerCategory
    },
    accommodationCategoryIndex() {
        const categories = {}

        for(let i in this.accommodations) {
            const accommodation = this.accommodations[i]
            if(accommodation.categories !== null && accommodation.categories !== undefined && accommodation.categories.length > 0) {
                for(let n in accommodation.categories) {
                    const category = accommodation.categories[n]
                    categories[category.uuid] = category
                }
            }
        }

        return categories
    },
    accommodationCategories() {
        const keys = Object.keys(this.accommodationsPerCategory)
        const categories = []

        for(let i in keys) {
            const key = keys[i]
            categories.push(this.accommodationCategoryIndex[key])
        }

        return categories
    },
    accommodations() {
        return this.$store.state.order.lineItems[0].item.accommodations
    },
    primaryColor() {
      return this.$store.getters.primaryColor
    }
  },
  methods: {
    cheapestVariationOption(value) {
      const accommodation = JSON.parse(JSON.stringify(value))
      if(!accommodation || !accommodation.variations || !Array.isArray(accommodation.variations)) {
        return accommodation.price
      }
      const variations = accommodation.variations

      if(!variations.length > 0) {
        return accommodation.price
      }
      let currentValue = variations[0].price
      variations.forEach(function (variation) {
        if(currentValue.amount > variation.price.amount) {
          currentValue = variation.price
        }
      })
      currentValue.amount += accommodation.price.amount
      return currentValue
    },
    totalPrice(accommodationPrice, variationPrice) {
      if(accommodationPrice.currency_code !== variationPrice.currency_code) {
        return null
      }
      let totalPrice = {
        amount: accommodationPrice.amount + variationPrice.amount,
        currency_code: accommodationPrice.currency_code
      }
      return totalPrice
    }
  },

}
</script>

<style>
.no-padding >>> .v-expansion-panel-content__wrap {
  padding: 0;
}

.v-list-item__title, .v-list-item__subtitle {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: inherit !important;
    white-space: inherit !important;
}
</style>
