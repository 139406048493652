<template>
  <v-container fluid>
    <v-row
      align="center"
      dense
    >
      <v-col cols="12" v-if="hasError" class="pb-3">
        <v-alert type="error" dense border="left" class="mb-0">{{ $t('coupon.error') }}</v-alert>
      </v-col>
      <v-col cols="12" v-if="success" class="pb-3">
        <v-alert type="success" dense border="left" class="mb-0">{{ $t('coupon.success') }}</v-alert>
      </v-col>
      <v-col cols="7" md="9">
        <v-text-field
          :label="$t('coupon.code')"
          :filled="false"
          outlined
          dense
          hide-details
          clearable
          @click:clear="clearCoupon"
          @keydown.enter="applyCoupon"
          :success="success"
          :error="hasError"
          :disabled="processingCoupon || processingPayment"
          v-model="couponInput"
        ></v-text-field>
      </v-col>
      <v-col cols="5" md="3">
        <v-btn height="40" block v-if="!hasActiveCoupon" color="primary" @click="applyCoupon" :disabled="processingPayment || processingCoupon || !couponInput"
          :loading="processingCoupon">{{ $t('button.apply') }}
        </v-btn>
        <v-btn height="40" block v-if="hasActiveCoupon" color="error" @click="clearCoupon" :disabled="processingPayment || processingCoupon" :loading="processingCoupon">
          {{ $t('button.clear') }}
        </v-btn>
      </v-col>
    </v-row>
	</v-container>
</template>

<script>
export default {
	name: "Coupon",
	methods: {
		clearCoupon() {
			this.$store.dispatch('clearCoupon')
		},
		applyCoupon() {
      const payload = {
        couponCode: this.couponInput,
        locationUUID: this.$store.state.locationUUID
      }
      this.$store.dispatch('checkIfCouponIsValid', payload).then(() => {
        if (this.orderTotal.amount < 0) {
          const dependentPayments = this.$store.state.dependentPayments
          if (Array.isArray(dependentPayments) && dependentPayments.length > 0) {
            const firstDependentPayment = dependentPayments[0]
            const newTotalAmount = firstDependentPayment.amount + this.orderTotal.amount
            if (newTotalAmount > 0) {
              firstDependentPayment.amount = newTotalAmount
            }
          }
        }
      })
    }
	},
	computed: {
		couponInput: {
			get() {
				return this.$store.getters.couponCodeInput
			},
			set(couponCode) {
				couponCode = couponCode?.toUpperCase() ?? couponCode
				this.$store.commit('updateCouponCodeInput', couponCode)
			}
		},
		hasActiveCoupon() {
			return this.$store.getters.hasActiveCoupon
		},
		success() {
      return this.$store.getters.couponValidSuccess
    },
    hasError() {
      return this.$store.getters.couponValidError
    },
    processingCoupon() {
      return this.$store.getters.processingCoupon
    },
    processingPayment() {
      return this.$store.state.paymentInProgress
    },
    orderTotal() {
      return this.$store.getters.orderTotal
    }
  }
}
</script>

<style scoped>

</style>
