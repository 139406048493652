<template>
    <v-container fluid style="max-width: 700px;">
        <v-img 
            src="https://www.contiki.com/media/vezgmicz/pano044001-pano2-2.jpg?center=0.6643835616438356%2C0.667910447761194&format=webp&mode=crop&width=1500&height=1500&quality=80" 
            style="height: 300px; width: 100%"
            :gradient="`to top right, ${primaryColorGradient}, ${primaryColorGradient}`"
        >
        <span style="size:500px;" class="white--text font-weight-bold">{{$t('hello')}}</span>
        </v-img>
    
    </v-container>
</template>

<script>

export default ({
    name: 'FlightReview', 
    namespace: 'flight_review', 

    computed: {
        primaryColorGradient() {
            let primary = this.$store.getters.primaryColor
            primary += '60'
            return primary 
        }
    }, 
})
</script>
