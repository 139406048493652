<template>
  <v-container fluid class="pt-0">
    <v-row
      align="center"
      dense
    >
      <v-col v-if="success" cols="12" class="pb-3">
        <v-alert
          type="success"
          border="left"
          dense
          class="mb-0"
        >
          {{ $t(response.message) }}
        </v-alert>
      </v-col>
      <v-col v-if="hasError" cols="12" class="pb-3">
        <v-alert
          type="error"
          border="left"
          dense
          class="mb-0"
        >
          {{ $t(response.message) }}
        </v-alert>
      </v-col>
      <template v-for="field in uiFields">
        <v-col
          :key="field.key"
          cols="7"
          md="9"
        >
          <uiField
            :value="field"
            :instance="giftCardPaymentMethodInstance"
            hide-details
            :filled="false"
            dense
            outlined
            append-icon-image
            @input="processInput"
          />
        </v-col>
      </template>
      <v-col
        cols="5"
        md="3"
      >
        <v-btn
          block
          height="40"
          color="primary"
          :disabled="processingPayment || processingGiftCard || !code"
          :loading="processingGiftCard"
          @click="checkGiftCard"
        >
          {{ $t('button.apply') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import uiField from "@/components/uiField"

export default {
  name: "GiftCardAddToPayment",
  components: {
    uiField
  },
  props: {
    giftCardPaymentMethodInstance: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      field: null,
      code: null,
      response: null
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    orderTotal() {
      return this.$store.getters.orderTotal
    },
    processingGiftCard() {
      return this.$store.state.processingGiftCard
    },
    processingPayment() {
      return this.$store.state.processingPayment
    },
    uiFields() {
      if (typeof this.giftCardPaymentMethodInstance !== 'undefined' && this.giftCardPaymentMethodInstance !== null) {
        if (Array.isArray(this.giftCardPaymentMethodInstance.uiFields) && this.giftCardPaymentMethodInstance.uiFields.length > 0) {
          return this.giftCardPaymentMethodInstance.uiFields
        }
      }
      return []
    },
    success() {
      return typeof this.response !== 'undefined' && this.response !== null && this.response.success === true
    },
    hasError() {
      return typeof this.response !== 'undefined' && this.response !== null && this.response.success === false
    },
    getOrderLineItems() {
      return this.$store.getters.getOrderLineItems
    },
  },
  methods: {
    clearGiftCard() {
      this.$store.commit('setDependentPayments', [])
    },
    checkGiftCard() {
      this.$store.commit('setDependentPayments', [])
      this.response = null

      if (typeof this.giftCardPaymentMethodInstance === 'undefined' || this.giftCardPaymentMethodInstance === null) {
        return
      }

      const request = this.$store.getters.balanceRequest(this.giftCardPaymentMethodInstance)
      request.payload = {
        code: this.code,
        lineItems: this.getOrderLineItems
      }

      return this.$store.dispatch('getBalance', request).then(result => {
        if (result) {
          this.response = result
          if (typeof result.balance !== 'undefined' && result.balance !== null) {
            let giftCardUsedAmount = result.balance.amount
            if (result.balance.amount > this.orderTotal.amount) {
              giftCardUsedAmount = this.orderTotal.amount
            }
            this.$store.commit('addDependentPayment', {
              paymentMethodInstanceUUID: this.giftCardPaymentMethodInstance.uuid,
              namespace: this.giftCardPaymentMethodInstance.namespace,
              amount: giftCardUsedAmount,
              details: {
                giftcard: this.code
              }
            })
          }
        } else {
          this.response = {
            success: false,
            message: 'No response data received'
          }
        }
      }).catch(() => {
        this.response = {
          success: false,
          message: 'Service error'
        }
      })
    },
    processInput(value) {
      this.field = value
      if (value && value.value) {
        this.code = value.value
      } else {
        this.code = null
        this.response = null
        this.clearGiftCard()
      }
    },
    getGiftCardStatusBody(code, organizationUUID, cart, totalAmount) {
      let cartForGiftCardStatusQuery = null
      if (cart && Array.isArray(cart.lineItems)) {
        cartForGiftCardStatusQuery = []
        for (let index = 0; index < cart.lineItems.length; index++) {
          const lineItem = cart.lineItems[index]
          if (lineItem) {
            const itemUUID = (lineItem && lineItem.item && lineItem.item.uuid) ? lineItem.item.uuid : ''
            const lineItemVariations = (Array.isArray(lineItem.variations) && lineItem.variations.length > 0) ? lineItem.variations.map(v => v.uuid) : []
            cartForGiftCardStatusQuery.push({
              item: itemUUID,
              quantity: lineItem.quantity,
              variations: lineItemVariations
            })
          }
        }
      }
      return {
        query: `query GetGiftCard($cart: [CartItem]) {\n
          giftCardStatus(code: "${code}", organization: "${organizationUUID}", cart: $cart) {\n
            success\n
            message\n
            payload\n
          }\n
        }`,
        variables: {
          cart: cartForGiftCardStatusQuery,
          totalAmount
        }
      }
    },
  }
}
</script>

<style scoped>

</style>