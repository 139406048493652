<template>
  <v-card
    id="checkout-step"
    v-if="checkoutStep !== null && typeof checkoutStep !== 'undefined'"
    align="left"
    flat
    tile
    :class="checkoutStep && checkoutStep.namespace === 'complete' ? 'pt-1' : ''"
  >
    <div
      class="mt-0 mb-0 pb-0"
      v-if="checkoutStep.namespace !== 'complete' && !orderHasTripLineItems"
    >
      <v-card-title align="left">{{ $t(checkoutStepTitle) }}</v-card-title>
      <v-card-subtitle align="left" v-if="checkoutStepDescription">{{
        $t(checkoutStepDescription)
      }}</v-card-subtitle>
    </div>
    <div
      class="ma-5 white--text pb-0 green"
      v-if="checkoutStep.namespace === 'complete'"
      style="border-radius: 5px"
    >
      <v-card-title align="left">{{ $t(checkoutStep.title) }}</v-card-title>
      <v-card-subtitle class="white--text" align="left">{{
        $t(checkoutStep.description)
      }}</v-card-subtitle>
      <v-card-text
        v-if="orderHasBookingLineItems || orderHasSubscriptionLineItems"
      >
        <v-card flat v-if="orderHasBookingLineItems" class="pb-2">
          <OrderBookings
            :booking-line-items="orderBookingLineItems"
          ></OrderBookings>
        </v-card>
        <v-card
          flat
          v-if="
            orderHasSubscriptionLineItems &&
            organizationUUID === 'c9632662-ce96-4261-91ee-eef98f5e9223'
          "
          class="pb-2"
        >
          <!-- Subscription vouchers removed until they are usable -->
          <OrderSubscriptions
            :subscription-line-items="orderSubscriptionLineItems"
          ></OrderSubscriptions>
        </v-card>
      </v-card-text>
    </div>
    <v-card-text>
      <template
        v-for="(checkoutSectionInstance, index) in checkoutSectionInstances"
      >
        <div
          :key="checkoutSectionInstance.namespace"
          v-if="
            checkoutSectionInstance.namespace !== 'pickup' &&
            checkoutSectionInstance.namespace !== 'coupon'
          "
        >
          <component
            v-if="
              whiteListedCheckoutSections[checkoutSectionInstance.namespace]
            "
            :is="whiteListedCheckoutSections[checkoutSectionInstance.namespace]"
          />
          <CheckoutSectionInstance
            v-else
            @emailIsValid="setEmail"
            @nameIsValid="setName"
            @duplicateEmailValidated="duplicateEmailValidated"
            :ref="checkoutSectionInstance.namespace"
            :checkout-section-instance="checkoutSectionInstance"
          />
          <div
            :key="checkoutSectionInstance.namespace + ':errors'"
            v-if="
              checkoutSectionInstanceErrors(checkoutSectionInstance.namespace)
                .length > 0
            "
          >
            <template
              v-for="(error, index) in checkoutSectionInstanceErrors(
                checkoutSectionInstance.namespace
              )"
            >
              <v-alert :key="index" type="error" one->
                {{ $t(error.message) }}
              </v-alert>
            </template>
          </div>
          <v-divider
            class="mt-5 mb-5"
            :key="index"
            v-if="index < checkoutSectionInstances.length - 1"
          />
        </div>
      </template>
    </v-card-text>
    <v-card-text v-if="errorMessage !== null">
      <v-alert prominent type="error" dismissible>
        <v-row align="center">
          <v-col class="grow">{{ errorMessage }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
    <v-card-text v-if="warningMessage !== ''">
      <v-alert prominent type="info" dismissible>
        <v-row align="center">
          <v-col class="grow" v-html="warningMessage"></v-col>
        </v-row>
      </v-alert>
    </v-card-text>
    <v-card-actions
      v-if="
        checkoutStep.namespace !== 'complete'"
    >
      <v-btn
        color="primary"
        x-large
        width="50%"
        v-if="previousStep"
        :disabled="
          processing ||
          isGettingAvailableTimeslots ||
          !previousStep ||
          processingPayment ||
          paymentIsPending
        "
        @click="back"
        text
      >
        {{ $t("Back") }}
      </v-btn>
      <v-btn
        v-if="!previousStep && hasReferrer"
        color="primary"
        x-large
        width="50%"
        :disabled="
          processing ||
          isGettingAvailableTimeslots ||
          previousStep ||
          processingPayment ||
          paymentIsPending
        "
        @click="backToReferrer"
        text
      >
        {{ $t("Back") }}
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="checkoutStep.namespace !== 'review'"
        x-large
        :width="(previousStep || hasReferrer) ? '50%' : '100%'"
        :loading="
          processing || isGettingAvailableTimeslots || processingPayment
        "
        :disabled="
          processing ||
          isGettingAvailableTimeslots ||
          processingPayment ||
          paymentIsPending ||
          !allUiFieldsValid ||
          !duplicateEmailValidation
        "
        @click="progressOrder"
        color="primary"
      >
        {{ $t("Continue") }}
      </v-btn>
      <v-btn
        v-else-if="
          Array.isArray(dependentPayments) &&
          dependentPayments.length > 0 &&
          (orderTotal.amount === 0 || orderTotal.amount === null)
        "
        x-large
        width="50%"
        :loading="processing || processingPayment || paymentIsPending"
        :disabled="
          processing || processingPayment || paymentIsPending || !allTermsAgreed
        "
        @click="processPayment"
        color="primary"
      >
        {{ $t("button.apply") }}
      </v-btn>
      <v-btn
        v-else-if="
          (selectedPaymentMethodInstance &&
            selectedPaymentMethodInstance.namespace === 'complete') ||
          orderTotal.amount === 0 ||
          orderTotal.amount === null
        "
        x-large
        width="50%"
        :loading="processing || processingPayment || paymentIsPending"
        :disabled="
          processing ||
          processingPayment ||
          !isSelectedPaymentMethodInstanceValid ||
          paymentIsPending
        "
        @click="processPayment"
        color="primary"
      >
        {{ $t("button.apply") }}
      </v-btn>
      <v-btn
        x-large
        v-else-if="
          selectedPaymentMethodInstance &&
          selectedPaymentMethodInstance.namespace !== 'complete'
        "
        :loading="processing || processingPayment || paymentIsPending"
        width="50%"
        :disabled="
          processing ||
          processingPayment ||
          !isSelectedPaymentMethodInstanceValid ||
          paymentIsPending ||
          !allTermsAgreed
        "
        @click="processPayment"
        color="primary"
      >
        {{ $t("Pay") }}
      </v-btn>
    </v-card-actions>
    <div
      style="
        position: sticky;
        position: -webkit-sticky;
        right: 0;
        bottom: 0;
        z-index: 50;
      "
      ref="bottom-stepper"
      v-if="checkoutStep.namespace !== 'review'"
    >
      <CheckoutBottomStepper
        @continue="progressOrder"
        :previousStep="previousStep"
        :processing="processing"
        :emailValid="emailValid"
        :nameValid="nameValid"
        v-if="false"
      />
    </div>
  </v-card>
</template>

<script>
import CheckoutSectionInstance from "./CheckoutSectionInstance";
import OrderBookings from "@/components/OrderBookings";
import OrderSubscriptions from "@/components/OrderSubscriptions";
import CheckoutBottomStepper from "@/components/checkout/CheckoutBottomStepper";

/**
 * @property {Object} paymentTransaction
 * @property {Array} uiFields
 * @property {Object} checkoutSectionInstance
 * @property {Object} billing_minimal
 */

export default {
  name: "CheckoutForm",
  components: {
    CheckoutBottomStepper,
    OrderSubscriptions,
    OrderBookings,
    CheckoutSectionInstance,
  },
  props: ["checkoutStep"],
  data: () => ({
    //checkoutSectionInstancesData: [],
    duplicateEmailValidation: true,
    processing: false,
    errorMessage: null,
    errors: [],
    scheduleRoute: null,
    fromSchedule: false,
    emailValid: false,
    nameValid: false,
  }),
  mounted() {
    // Setup duplicate email validation for Hvammsvík
    if (
      this.$store.state.organizationUUID ===
      "06a69a1f-8164-422e-b7d8-3753e04f6464"
    ) {
      this.duplicateEmailValidation = false;
    }
  },
  watch: {
    processingPayment(newValue, oldValue) {
      if (oldValue === true) {
        //this.errorMessage = null
      }
    },
    paymentResponse(res) {
      this.errorMessage = null;
      if (res && !res.success) {
        this.$store.commit("updatePaymentIsPending", false);
        this.errorMessage = this.$t("payment.notSuccess", {
          reason: res.paymentTransaction.message,
        });
      } else if (res && res.success) {
        if (
          res.paymentTransaction === null ||
          (res.paymentTransaction &&
            res.paymentTransaction.status === "success")
        ) {
          this.$store.commit("updatePaymentIsPending", false);

          /* ANALYTICS PURCHASE EVENT START */
          try {
            if (this.hasGoogleAnalytics) {
              let purchaseObject = this.analyticsPurchaseObject(
                res.paymentTransaction
              );
              this.$gtag.event("purchase", purchaseObject);
            }
            if (this.hasFacebookAnalytics) {
              let purchaseObject = this.fbPurchaseObject(
                res.paymentTransaction
              );
              window.fbq("track", "Purchase", purchaseObject);
            }

            // Send purchase info
            window.parent.postMessage(
              JSON.stringify({
                event: "purchase",
                ecommerce: this.analyticsPurchaseObject(res),
              }),
              "*"
            );
          } catch (e) {
            //
          }
          /* ANALYTICS PURCHASE EVENT END */

          this.$router.push({
            path:
              "/" +
              this.$route.params.organization +
              "/" +
              this.$route.params.channel +
              "/" +
              this.$route.params.order +
              "/" +
              this.nextStep.namespace,
          });
        } else if (
          res.paymentTransaction &&
          res.paymentTransaction.status === "pending"
        ) {
          this.$store.commit("updatePaymentIsPending", true);
          this.$store.commit("updatePendingPaymentLastCheckedDate", Date.now());
          this.$store.dispatch("checkPendingPayment", {
            paymentInstanceUUID:
              this.$store.getters.selectedPaymentMethodInstance.uuid,
            orderUUID: res.paymentTransaction.uuid,
          });
        }
      }
    },
    checkoutStep(newCheckoutStep) {
      if (
        newCheckoutStep !== null &&
        typeof newCheckoutStep !== "undefined" &&
        newCheckoutStep.namespace === "review"
      ) {
        this.$store.dispatch("checkOrderTotal");
      }
    },
  },
  computed: {
    whiteListedCheckoutSections() {
      const sections = {};

      sections["accommodation"] = () =>
        import("@/components/checkoutSectionInstances/Accommodations.vue");
      sections["flight_outbound"] = () =>
        import("@/components/checkoutSectionInstances/OutboundFlight.vue");
      sections["flight_return"] = () =>
        import("@/components/checkoutSectionInstances/ReturningFlight.vue");
      sections["passenger_information"] = () =>
        import(
          "@/components/checkoutSectionInstances/PassengerInformation.vue"
        );
      sections["flight_review"] = () =>
        import("@/components/checkoutSectionInstances/FlightReview.vue");
      sections["passenger_upsell"] = () =>
        import("@/components/checkoutSectionInstances/PassengerUpsell.vue");
      sections["payment_plan"] = () =>
        import("@/components/checkoutSectionInstances/PaymentPlan.vue");

      return sections;
    },
    dependentPayments() {
      return this.$store.state.dependentPayments;
    },
    selectedDeliveryService() {
      return this.$store.state.selectedDeliveryService;
    },
    referrer() {
      return document.referrer;
    },
    hasReferrer() {
		let referrerInSearchParams = false;
		try {
			const documentSearchParams = new URL(document.location).searchParams;
			const referrer = documentSearchParams.get('referrer');
			referrerInSearchParams = Boolean(referrer);
		} catch (e) {
			// We will do nothing here
		}
      return (
        document.referrer !== null &&
        document.referrer !== "" &&
        window.location.href.includes(document.referrer)
      ) || referrerInSearchParams;
    },
    organizationUUID() {
      return this.$store.state.organizationUUID;
    },
    processingPayment() {
      return this.$store.state.processingPayment;
    },
    orderHasTripLineItems() {
      return this.orderTripLineItems.length > 0;
    },
    orderHasBookingLineItems() {
      return this.orderBookingLineItems.length > 0;
    },
    orderHasSubscriptionLineItems() {
      return this.orderSubscriptionLineItems.length > 0;
    },
    orderBookingLineItems() {
      if (this.order !== null && this.order !== undefined) {
        if (Array.isArray(this.order.lineItems)) {
          return this.order.lineItems.filter(
            (lineItem) => lineItem && lineItem.namespace === "event"
          );
        }
      }
      return [];
    },
    orderSubscriptionLineItems() {
      if (this.order !== null && this.order !== undefined) {
        if (Array.isArray(this.order.lineItems)) {
          return this.order.lineItems.filter(
            (lineItem) => lineItem && lineItem.namespace === "subscription"
          );
        }
      }
      return [];
    },
    orderTripLineItems() {
      if (this.order !== null && this.order !== undefined) {
        if (Array.isArray(this.order.lineItems)) {
          return this.order.lineItems.filter(
            (lineItem) => lineItem && lineItem.namespace === "trip"
          );
        }
      }
      return [];
    },
    hasGoogleAnalytics() {
      return this.$store.getters.hasGoogleAnalytics;
    },
    hasFacebookAnalytics() {
      return this.$store.getters.hasFacebookAnalytics;
    },
    bookingOnly() {
      return this.$store.getters.bookingOnly;
    },
    paymentIsPending() {
      return this.$store.state.paymentIsPending;
    },
    orderTotal() {
      return this.$store.getters.orderTotal;
    },
    allTermsAgreed() {
      return this.$store.getters.allTermsAgreed;
    },
    organization() {
      return this.$store.state.organization;
    },
    checkoutStepTitle() {
      if (
        this.checkoutStep &&
        this.checkoutStep.namespace === "checkout" &&
        this.channel &&
        this.channel.data &&
        this.channel.data.display &&
        this.channel.data.display.title
      ) {
        return this.channel.data.display.title;
      }

      return this.checkoutStep.title || "";
    },
    checkoutStepDescription() {
      if (
        this.checkoutStep &&
        this.checkoutStep.namespace === "checkout" &&
        this.channel &&
        this.channel.data &&
        this.channel.data.display &&
        this.channel.data.display.description
      ) {
        return this.channel.data.display.description;
      }

      return "";
    },
    checkoutSectionInstancesData: {
      get() {
        return this.$store.state.checkoutSectionInstancesData;
      },
      set(value) {
        this.$store.commit("updateCheckoutInstanceData", value);
      },
    },
    channel() {
      return this.$store.state.channel;
    },
    channelIsExclusive() {
      return (
        this.channel.data !== null &&
        typeof this.channel.data !== "undefined" &&
        this.channel.data.locking !== null &&
        typeof this.channel.data.locking !== "undefined" &&
        this.channel.data.locking.negotiation !== null &&
        typeof this.channel.data.locking.negotiation !== "undefined" &&
        this.channel.data.locking.negotiation.customer_is_exclusive ===
          "customer_is_exclusive"
      );
    },
    channelContainsInvitee() {
      return (
        this.$store.state.channelUUID === "b2eb7dd7-255b-4716-82e5-867abbf55417"
      ); //TODO: Check for ticket_invitee checkout section on channel
    },
    paymentTransaction() {
      return this.paymentResponse
        ? this.paymentResponse.paymentTransaction
        : null;
    },
    paymentResponse: function () {
      return this.$store.state.paymentResponse;
    },
    order: function () {
      return this.$store.state.order;
    },
    isGettingAvailableTimeslots() {
      return this.$store.state.gettingAvailableTimeSlots;
    },
    nextStep: function () {
      return this.$store.getters.getNextCheckoutStep(
        this.checkoutStep.namespace
      );
    },
    previousStep: function () {
      return this.$store.getters.getPreviousCheckoutStep(
        this.checkoutStep.namespace
      );
    },
    checkoutSectionInstances: function () {
      return this.$store.getters.getCheckoutSectionInstances(
        this.checkoutStep.namespace
      );
    },
    primaryColor() {
      return this.$store.getters.primaryColor;
    },
    validateBeforePayment() {
      return this.$store.getters.validateBeforePayment;
    },
    isSelectedPaymentMethodInstanceValid() {
      return this.$store.getters.isSelectedPaymentMethodInstanceValid;
    },
    selectedPaymentMethodInstance() {
      return this.$store.getters.selectedPaymentMethodInstance;
    },
    analyticsCheckoutObject() {
      return this.$store.getters.checkoutObject;
    },
    allUiFieldsValid() {
      return true
    },
    warningMessage() {
      if (
        this.$store.getters.couponCodeInput !== null &&
        this.$store.getters.couponCodeInput !== "" &&
        !this.$store.getters.hasActiveCoupon
      ) {
        return this.$t("coupon.warning");
      }
      return "";
    },
  },
  methods: {
    availableCheckoutSections() {
      // Trying to just read from checkoutSectionInstances folder to see which checkout section instances are available and white listed
      return () =>
        require.context("./checkoutSectionInstances", true, /\w+\.vue$/i);
    },
    showCompletedStep() {
      this.checkoutStep.namespace = "complete";
    },
    analyticsPurchaseObject(paymentRequestResponse) {
      return this.$store.getters.purchaseObject(paymentRequestResponse);
    },
    fbPurchaseObject(paymentResponse) {
      return this.$store.getters.fbPurchaseObject(paymentResponse);
    },
    analyticsCheckoutStepProgress() {
      if (this.hasGoogleAnalytics) {
        this.$gtag.event("checkout_progress", this.analyticsCheckoutObject);
      }
    },
    analyticsClickPurchase() {
      try {
        if (this.hasGoogleAnalytics) {
          this.$gtag.event("clicked_purchase_button", {
            event_label: "Start purchase",
          });
        }
        if (this.hasFacebookAnalytics) {
          window.fbq("trackCustom", "StartPurchaseEvent");
        }

        // Starting payment (sending payment info)
        window.parent.postMessage(
          JSON.stringify({
            event: "add_payment_info",
            ecommerce: this.$store.getters.paymentObject,
          }),
          "*"
        );
      } catch (e) {
        // Do nothing
      }
    },
    backToReferrer() {
      this.processing = true;

      // document.referrer will only show the origin without search params
      // Making it difficult to return the user to the actual origin where we use e.g. ?uuid=<widget uuid>
      // With the below we will use the referrer in the provided link and document.referrer as backup
      const searchParams = new URLSearchParams(window.location.search);
      const referrer = searchParams.get("referrer");
      if (referrer) {
        window.location = referrer + "&time=" + new Date().getTime();
      } else {
        window.location = document.referrer;
      }
    },
    back() {
      if (!this.previousStep && this.orderHasTripLineItems) {
        //need to figure out how to store category uuid if
        //item.salecloud.is used category uuid
        window.location.href = `https://item.salescloud.is/?uuid=${
          this.orderTripLineItems[0].uuid
        }&channel=${this.$store.state.channelUUID}&organization=${
          this.organizationUUID
        }&lang=${
          this.$i18n.locale
        }&time=${Date.now()}&miq=1&maq=4&&category=category=931e8412-6a37-4f15-8d3c-1870f0b435f0}`;
      }

      this.$router.push({
        path:
          "/" +
          this.$route.params.organization +
          "/" +
          this.$route.params.channel +
          "/" +
          this.$route.params.order +
          "/" +
          this.previousStep.namespace,
      });
    },
    checkoutSectionInstanceErrors(namespace) {
      const errors = [];

      for (let i in this.errors) {
        if (this.errors[i].ref.includes(namespace)) {
          errors.push(this.errors[i]);
        }
      }

      return errors;
    },
    processPayment() {
      if (
        Array.isArray(this.dependentPayments) &&
        this.dependentPayments.length > 0
      ) {
        if (this.orderTotal && this.orderTotal.amount === 0) {
          const first = this.dependentPayments[0];
          if (first) {
            const paymentMethodInstanceIndex =
              this.$store.state.paymentMethodInstances.findIndex(
                (instance) =>
                  instance && instance.uuid === first.paymentMethodInstanceUUID
              );
            if (paymentMethodInstanceIndex >= 0) {
              this.$store.commit(
                "updateSelectedPaymentMethodInstanceIndex",
                paymentMethodInstanceIndex
              );
            }
          }
        }
      }

      if (!this.isSelectedPaymentMethodInstanceValid) {
        this.errorMessage = this.$t("fields.needInput");
      } else {
        this.errorMessage = null;
        this.analyticsClickPurchase();

        const payload = {
          selectedPaymentMethodInstance:
            this.$store.getters.selectedPaymentMethodInstance,
          paymentRequest: this.$store.getters.paymentRequest
        };

        this.$store.dispatch("processPayment", payload);
      }
    },
    progressOrder() {
      // eslint-disable-next-line no-console
      this.processing = true;
      this.errorMessage = null;
      this.checkoutSectionInstancesData = [];

      if (
        this.checkoutSectionInstances.some(
          (checkoutSectionInstance) =>
            checkoutSectionInstance &&
            checkoutSectionInstance.namespace === "order_delivery"
        )
      ) {
        if (!this.selectedDeliveryService) {
          this.errorMessage = this.$t("mustSelectDeliveryService");
          return;
        }
      }

      for (let i in this.$refs) {
        let checkoutSectionInstanceComponent = this.$refs[i][0];

        if (checkoutSectionInstanceComponent !== undefined) {
          const checkoutSectionInstanceData = {
            namespace:
              checkoutSectionInstanceComponent.checkoutSectionInstance
                .namespace,
            data: {},
          };

          for (let n in checkoutSectionInstanceComponent.checkoutSectionInstance
            .uiFields) {
            let uiFieldComponent =
              checkoutSectionInstanceComponent.checkoutSectionInstance.uiFields[
                n
              ];
            checkoutSectionInstanceData.data[uiFieldComponent.key] =
              uiFieldComponent.value;
          }

          checkoutSectionInstanceData.data = JSON.stringify(
            checkoutSectionInstanceData.data
          );
          this.checkoutSectionInstancesData.push(checkoutSectionInstanceData);
        }
      }

      this.$store
        .dispatch("processCheckoutStep", {
          step: this.checkoutStep.namespace,
          values: this.checkoutSectionInstancesData,
        })
        .then((response) => {
          this.errors = [];

          if (response.data.data.processCheckoutStep) {
            if (response.data.data.processCheckoutStep.success) {
              const cart = this.$store.state.order;

              for (let i in this.checkoutSectionInstancesData) {
                const namespace =
                  this.checkoutSectionInstancesData[i].namespace;

                if (
                  namespace === null ||
                  namespace === undefined ||
                  namespace === "payment"
                ) {
                  continue;
                }

                const data = this.checkoutSectionInstancesData[i];
                // eslint-disable-next-line no-console
                //console.log(data.data)

                try {
                  cart[namespace] = JSON.parse(data.data);
                } catch (e) {
                  // eslint-disable-next-line no-empty
                  // eslint-disable-next-line no-console
                  console.log(e);
                }
              }

              const cartKeys = Object.keys(cart);

              for (let i in cartKeys) {
                const checkoutSectionInstanceNamespace = cartKeys[i];
                const checkoutSectionInstance =
                  this.$store.state.checkoutSectionInstances.find(
                    (checkoutSectionInstance) =>
                      checkoutSectionInstance.namespace ===
                      checkoutSectionInstanceNamespace
                  );

                if (
                  checkoutSectionInstance === null ||
                  checkoutSectionInstance === undefined
                ) {
                  continue;
                }

                for (let n in checkoutSectionInstance.uiFields) {
                  const uiField = checkoutSectionInstance.uiFields[n];

                  if (uiField === null || uiField === undefined) {
                    continue;
                  }
                  if (
                    uiField.required === true &&
                    (uiField.value === null ||
                      uiField.value === undefined ||
                      uiField.value === "" ||
                      uiField.value === false)
                  ) {
                    this.errors.push({
                      ref: checkoutSectionInstance.namespace,
                      message:
                        this.$t(uiField.label) + " " + this.$t("is required"),
                    });
                  }
                }
              }

              if (
                !cart.lineItems.some(
                  (lineItem) => lineItem.namespace === "trip"
                )
              ) {
                if (
                  (cart.billing_minimal === undefined ||
                    cart.billing_minimal === null) &&
                  (cart.customer_profile_billing === undefined ||
                    cart.customer_profile_billing === null)
                ) {
                  this.errors.push({
                    ref: "billing_minimal",
                    message: "Billing is required",
                  });
                }

                if (
                  cart.billing_minimal &&
                  cart.billing_minimal.ssn !== null &&
                  cart.billing_minimal.ssn !== undefined &&
                  cart.billing_minimal.ssn.replace(/\s/g, "").length !== 10
                ) {
                  this.errors.push({
                    ref: "billing_minimal:ssn",
                    message:
                      "Icelandic social security number must be 10 digits",
                  });
                }

                if (
                  cart.billing_minimal &&
                  cart.billing_minimal.name &&
                  cart.billing_minimal.name.replace(/\s/g, "").length < 3
                ) {
                  this.errors.push({
                    ref: "billing_minimal:name",
                    message: "Name must be at least 3 characters",
                  });
                }
              }

              //Age check for Bankastræti Club, B-Club, B Reykjavík ehf.
              if (
                this.$store.state.organizationUUID ===
                  "c9632662-ce96-4261-91ee-eef98f5e9223" &&
                cart.billing_minimal.ssn !== null &&
                cart.billing_minimal.ssn !== undefined &&
                cart.billing_minimal.ssn !== "1505002220" &&
                this.errors.length === 0
              ) {
                const firstSix = cart.billing_minimal.ssn.substr(0, 6);
                let year = firstSix.substr(4, 2);
                const now = new Date();
                const thisYear = (now.getUTCFullYear() + "").substr(2, 2) * 1;

                if (year < thisYear) {
                  year = "20" + year;
                } else {
                  year = "19" + year;
                }

                const dateOfBirth =
                  firstSix.substr(2, 2) +
                  "/" +
                  firstSix.substr(0, 2) +
                  "/" +
                  year;
                const date = new Date(dateOfBirth);
                const diff = now.getTime() - date.getTime();
                const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
                let minAge = 22;

                if (
                  this.$store.state.channelUUID !==
                  "d54715be-c71d-4ce8-b87e-633641836037"
                ) {
                  minAge = 20;
                }

                if (age < minAge) {
                  this.errors.push({
                    ref: "billing_minimal:ssn",
                    message: "You must be at least " + minAge + " years old.",
                  });
                }
              }

              /* ANALYTICS CHECKOUT PROGRESS START */
              this.analyticsCheckoutStepProgress();
              /* ANALYTICS CHECKOUT PROGRESS END */

              if (this.errors.length === 0) {
                this.$store.commit("updateCart", cart);

                this.$router.push({
                  path:
                    "/" +
                    this.$route.params.organization +
                    "/" +
                    this.$route.params.channel +
                    "/" +
                    this.$route.params.order +
                    "/" +
                    this.nextStep.namespace,
                });
              } else {
                this.errorMessage =
                  "There are errors on the page. Review them and try again.";
              }
            } else {
              this.errorMessage =
                response.data.data.processCheckoutStep.message;
            }
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    duplicateEmailValidated(valid) {
      this.duplicateEmailValidation =
        this.$store.state.organizationUUID ===
          "06a69a1f-8164-422e-b7d8-3753e04f6464" && valid;
    },
    setEmail(value) {
      this.emailValid = value;
    },
    setName(value) {
      this.nameValid = value;
    },
  },
};
</script>
