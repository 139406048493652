<template>
  <div class="locale-changer">
    <v-menu top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          :dark="dark"
          label
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <span
            class="text-uppercase"
            style="font-weight: 400; font-size: 14px;"
          >
            {{ $i18n.locale }}
          </span>
        </v-chip>
        <v-btn
          v-if="dark && false"
          text
          dark
          small
          v-bind="attrs"
          v-on="on"
        >
          {{ $i18n.locale }}
        </v-btn>
        <v-btn
          v-if="false"
          text
          small
          v-bind="attrs"
          v-on="on"
        >
          {{$i18n.locale}}
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          v-for="(item, index) in langs"
          :key="index"
          @click="$i18n.locale = item"
        >
          <v-list-item-title class="text-uppercase text-center">{{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data () {
    return { langs: ['is', 'en'] }
  },
  computed: {
    dark() {
      return this.$vuetify.theme.dark
    }
  }
}
</script>
