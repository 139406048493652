var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"checkout"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{staticClass:"fill-height primary",attrs:{"align":"center","justify":"center"}},[(!_vm.dark)?_c('v-col',{staticClass:"primary pt-0 pb-0",attrs:{"order-md":"0","order-sm":"0","order":"0","md":"6","sm":"12","cols":"12"}},[(
            _vm.organization &&
            _vm.organization.logo &&
            _vm.organization.logo.file &&
            _vm.organization.logo.file.sources &&
            _vm.organization.logo.file.sources.original &&
            _vm.inIframe
          )?_c('v-row',{class:_vm.mobile ? 'pt-0 pb-0' : 'pt-0 pb-0 px-10'},[_c('v-col',{staticClass:"pt-3 pb-3",attrs:{"cols":"10"}},[_c('div',{staticClass:"pl-4"},[_c('v-img',{staticStyle:{"max-height":"50px !important"},attrs:{"src":_vm.organization.logo.file.sources.original,"max-height":"50px","position":"left","contain":""}})],1)])],1):_vm._e(),_c('CartInCheckout',{class:_vm.mobile ? '' : 'px-10',attrs:{"order":_vm.order}})],1):_vm._e(),(_vm.dark)?_c('v-col',{staticClass:"pt-0 pb-0",class:!_vm.mobile ? 'fill-height' : '',attrs:{"order-md":"0","order-sm":"0","order":"0","md":"6","sm":"12","cols":"12"}},[(
            _vm.organization &&
            _vm.organization.logo &&
            _vm.organization.logo.file &&
            _vm.organization.logo.file.sources &&
            _vm.organization.logo.file.sources.original
          )?_c('v-row',{staticClass:"pt-0 pb-0"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"10"}},[(
                _vm.organization.uuid !== '06a69a1f-8164-422e-b7d8-3753e04f6464'
              )?_c('div',[_c('v-img',{staticStyle:{"max-height":"50px !important"},attrs:{"src":_vm.organization.logo.file.sources.original,"max-height":"50px","position":"left"}})],1):_vm._e()])],1):_vm._e(),_c('CartInCheckout',{attrs:{"order":_vm.order}})],1):_vm._e(),_c('v-col',{class:_vm.dark ? 'pa-0' : 'white pa-0',attrs:{"order-md":"0","order-sm":"1","order":"1","md":"6","sm":"12","cols":"12"}},[_c('v-row',{staticClass:"ma-0",class:_vm.mobile ? '' : 'px-10',attrs:{"align":"center"}},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"10"}},[_c('v-breadcrumbs',{staticClass:"px-1",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),_c('v-col',{staticClass:"px-4 text-end",attrs:{"cols":"2"}},[_c('LocaleChanger')],1)],1),_c('CheckoutForm',{class:_vm.mobile ? 'pb-5' : 'px-10',attrs:{"id":"checkout-form","checkout-step":_vm.checkoutStep}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }