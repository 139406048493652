<template>
  <v-container fluid class="pt-0 pb-0" fill-height>
    <v-row align="left" justify="top" class="fill-height pt-0 pb-0">
      <v-col cols="6" offset="3">
        <template v-for="(checkoutSection, index) in checkoutSections">
          <CheckoutSectionInstance :key="checkoutSection.namespace" :checkout-section-instance="checkoutSection" />
          <v-divider :key="index" />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import CheckoutSectionInstance from "../components/CheckoutSectionInstance";

  export default {
    name: 'checkout',
    components: {
      CheckoutSectionInstance
    },
    computed: {
      checkoutSections: function() {
        return this.$store.state.checkoutSections
      },
    },
  }
</script>
