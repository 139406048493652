<template>
  <v-container
    fluid
    :style="'box-shadow: 0 -4px 12px 0 #aaaaaa36' "
    style="padding: 12px 48px;"
  >
    <v-row align="center" justify="space-between">
      <v-col cols="auto">
        <div style="font-weight: 500; font-size: 14px;">
          {{ $t('Total') }}
        </div>
        <div style="font-weight: 700; font-size: 32px;">
          {{ orderTotal | formattedPrice }}
        </div>
      </v-col>
      <v-col cols="auto">
        <v-btn 
          color="primary" 
          depressed 
          style="font-weight: 900;" 
          x-large 
          @click="progressOrder"
          :loading="processing || isGettingAvailableTimeslots || processingPayment"
          :disabled="
            processing ||
            isGettingAvailableTimeslots ||
            processingPayment ||
            paymentIsPending ||
            !allUiFieldsValid
          "
          >
          {{ $t('Continue') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CheckoutBottomStepper",
  props: {
    processing: {
      type: Boolean,
      default: false
    },
    emailValid: {
      type: Boolean,
      default: false
    },
    nameValid: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    orderTotal() {
      if (typeof this.order !== 'undefined' && this.order !== null) {
        if (typeof this.order.orderTotal !== 'undefined' && this.order.orderTotal !== null) {
          return this.order.orderTotal
        }
      }
      return null
    },
    organization() {
      return this.$store.state.organization
    },
    primaryColor() {
      if (typeof this.organization !== 'undefined' && this.organization !== null) {
        if (typeof this.organization.primary_color !== 'undefined' && this.organization.primary_color !== null) {
          return this.organization.primary_color
        }
      }
      return null
    },
    processingPayment() {
      return this.$store.state.processingPayment
    },
    isGettingAvailableTimeslots() {
      return this.$store.state.gettingAvailableTimeSlots
    },
    allUiFieldsValid() {
      return this.emailValid === true && this.nameValid === true
    }
  }, 
  methods: {
    progressOrder() {
      this.$emit('continue')
    }
  }
}
</script>

<style scoped>

</style>