<template>
  <div>
    <template v-for="(checkoutStep, index) in checkoutSteps">
      <v-divider :key="checkoutStep.namespace + '-div'" />
      <h5 class="text-h5 mb-3 mt-3 mx-2 font-weight-black" :key="index">Step #{{ index + 1}}: {{ checkoutStep.title }}</h5>
      <v-row :key="checkoutStep.namespace" align="left" justify="top" class="fill-height pt-0 pb-0">
        <v-col class="mb-5" order-md="0" order-sm="1" order="1" md="6" sm="12" cols="12">
          <v-row>
            <v-col cols="10">
              <v-breadcrumbs class="pl-4" :items="breadcrumbs">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
            </v-col>
            <v-col cols="2">
              <LocaleChanger/>
            </v-col>
          </v-row>
          <CheckoutForm :checkout-step="checkoutStep" class="pb-5"/>
        </v-col>
        <v-col v-if="!dark" order-md="1" order-sm="0" order="0" class="grey lighten-3 pt-0 pb-0"
            :class="!mobile ? 'fill-height':''" md="6" sm="12" cols="12">
          <CartInCheckout :order="order"/>
        </v-col>
        <v-col v-if="dark" order-md="1" order-sm="0" order="0" class="pt-0 pb-0"
            :class="!mobile ? 'fill-height':''" md="6" sm="12" cols="12">
          <CartInCheckout :order="order"/>
        </v-col>
      </v-row>
      <v-divider :key="checkoutStep.namespace + '-div2'" />
    </template>
  </div>
</template>

<script>

import CheckoutForm from "../components/CheckoutForm"
import CartInCheckout from "../components/CartInCheckout";
import LocaleChanger from "../components/LocaleChanger";

export default {
  name: 'Order',
  components: {
    CheckoutForm,
    LocaleChanger,
    CartInCheckout
  },
  computed: {
    dark() {
			return this.$vuetify.theme.dark
		},
    checkoutSteps() {
      return this.$store.state.checkoutSteps
    },
    mobile() {

			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return true
				case 'sm':
					return true
				case 'md':
					return false
				case 'lg':
					return false
				case 'xl':
					return false
			}

			return false
		},
    order() {
			return {
        orderTotal: {
          amount: 200,
          currency_code: 'ISK'
        },
        lineItems: [
          {
            uuid: '1231231231',
            label: 'Test',
            line_item_label: 'Test',
            item: {
              title: 'Test',
              uuid: '123131231',
            },
            unitPrice: {
              amount: 100,
              currency_code: 'ISK'
            },
            quantity: 2,
            totalPrice: {
              amount: 0,
              currency_code: 'ISK'
            }
          }
        ]
      }
		},
  },
  mounted() {
    this.$store.commit('updateCart', this.order)
  }
}
</script>
