var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.checkoutStep !== null && typeof _vm.checkoutStep !== 'undefined')?_c('v-card',{class:_vm.checkoutStep && _vm.checkoutStep.namespace === 'complete' ? 'pt-1' : '',attrs:{"id":"checkout-step","align":"left","flat":"","tile":""}},[(_vm.checkoutStep.namespace !== 'complete' && !_vm.orderHasTripLineItems)?_c('div',{staticClass:"mt-0 mb-0 pb-0"},[_c('v-card-title',{attrs:{"align":"left"}},[_vm._v(_vm._s(_vm.$t(_vm.checkoutStepTitle)))]),(_vm.checkoutStepDescription)?_c('v-card-subtitle',{attrs:{"align":"left"}},[_vm._v(_vm._s(_vm.$t(_vm.checkoutStepDescription)))]):_vm._e()],1):_vm._e(),(_vm.checkoutStep.namespace === 'complete')?_c('div',{staticClass:"ma-5 white--text pb-0 green",staticStyle:{"border-radius":"5px"}},[_c('v-card-title',{attrs:{"align":"left"}},[_vm._v(_vm._s(_vm.$t(_vm.checkoutStep.title)))]),_c('v-card-subtitle',{staticClass:"white--text",attrs:{"align":"left"}},[_vm._v(_vm._s(_vm.$t(_vm.checkoutStep.description)))]),(_vm.orderHasBookingLineItems || _vm.orderHasSubscriptionLineItems)?_c('v-card-text',[(_vm.orderHasBookingLineItems)?_c('v-card',{staticClass:"pb-2",attrs:{"flat":""}},[_c('OrderBookings',{attrs:{"booking-line-items":_vm.orderBookingLineItems}})],1):_vm._e(),(
          _vm.orderHasSubscriptionLineItems &&
          _vm.organizationUUID === 'c9632662-ce96-4261-91ee-eef98f5e9223'
        )?_c('v-card',{staticClass:"pb-2",attrs:{"flat":""}},[_c('OrderSubscriptions',{attrs:{"subscription-line-items":_vm.orderSubscriptionLineItems}})],1):_vm._e()],1):_vm._e()],1):_vm._e(),_c('v-card-text',[_vm._l((_vm.checkoutSectionInstances),function(checkoutSectionInstance,index){return [(
          checkoutSectionInstance.namespace !== 'pickup' &&
          checkoutSectionInstance.namespace !== 'coupon'
        )?_c('div',{key:checkoutSectionInstance.namespace},[(
            _vm.whiteListedCheckoutSections[checkoutSectionInstance.namespace]
          )?_c(_vm.whiteListedCheckoutSections[checkoutSectionInstance.namespace],{tag:"component"}):_c('CheckoutSectionInstance',{ref:checkoutSectionInstance.namespace,refInFor:true,attrs:{"checkout-section-instance":checkoutSectionInstance},on:{"emailIsValid":_vm.setEmail,"nameIsValid":_vm.setName,"duplicateEmailValidated":_vm.duplicateEmailValidated}}),(
            _vm.checkoutSectionInstanceErrors(checkoutSectionInstance.namespace)
              .length > 0
          )?_c('div',{key:checkoutSectionInstance.namespace + ':errors'},[_vm._l((_vm.checkoutSectionInstanceErrors(
              checkoutSectionInstance.namespace
            )),function(error,index){return [_c('v-alert',{key:index,attrs:{"type":"error","one-":""}},[_vm._v(" "+_vm._s(_vm.$t(error.message))+" ")])]})],2):_vm._e(),(index < _vm.checkoutSectionInstances.length - 1)?_c('v-divider',{key:index,staticClass:"mt-5 mb-5"}):_vm._e()],1):_vm._e()]})],2),(_vm.errorMessage !== null)?_c('v-card-text',[_c('v-alert',{attrs:{"prominent":"","type":"error","dismissible":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(_vm._s(_vm.errorMessage))])],1)],1)],1):_vm._e(),(_vm.warningMessage !== '')?_c('v-card-text',[_c('v-alert',{attrs:{"prominent":"","type":"info","dismissible":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow",domProps:{"innerHTML":_vm._s(_vm.warningMessage)}})],1)],1)],1):_vm._e(),(
      _vm.checkoutStep.namespace !== 'complete')?_c('v-card-actions',[(_vm.previousStep)?_c('v-btn',{attrs:{"color":"primary","x-large":"","width":"50%","disabled":_vm.processing ||
        _vm.isGettingAvailableTimeslots ||
        !_vm.previousStep ||
        _vm.processingPayment ||
        _vm.paymentIsPending,"text":""},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")]):_vm._e(),(!_vm.previousStep && _vm.hasReferrer)?_c('v-btn',{attrs:{"color":"primary","x-large":"","width":"50%","disabled":_vm.processing ||
        _vm.isGettingAvailableTimeslots ||
        _vm.previousStep ||
        _vm.processingPayment ||
        _vm.paymentIsPending,"text":""},on:{"click":_vm.backToReferrer}},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")]):_vm._e(),_c('v-spacer'),(_vm.checkoutStep.namespace !== 'review')?_c('v-btn',{attrs:{"x-large":"","width":(_vm.previousStep || _vm.hasReferrer) ? '50%' : '100%',"loading":_vm.processing || _vm.isGettingAvailableTimeslots || _vm.processingPayment,"disabled":_vm.processing ||
        _vm.isGettingAvailableTimeslots ||
        _vm.processingPayment ||
        _vm.paymentIsPending ||
        !_vm.allUiFieldsValid ||
        !_vm.duplicateEmailValidation,"color":"primary"},on:{"click":_vm.progressOrder}},[_vm._v(" "+_vm._s(_vm.$t("Continue"))+" ")]):(
        Array.isArray(_vm.dependentPayments) &&
        _vm.dependentPayments.length > 0 &&
        (_vm.orderTotal.amount === 0 || _vm.orderTotal.amount === null)
      )?_c('v-btn',{attrs:{"x-large":"","width":"50%","loading":_vm.processing || _vm.processingPayment || _vm.paymentIsPending,"disabled":_vm.processing || _vm.processingPayment || _vm.paymentIsPending || !_vm.allTermsAgreed,"color":"primary"},on:{"click":_vm.processPayment}},[_vm._v(" "+_vm._s(_vm.$t("button.apply"))+" ")]):(
        (_vm.selectedPaymentMethodInstance &&
          _vm.selectedPaymentMethodInstance.namespace === 'complete') ||
        _vm.orderTotal.amount === 0 ||
        _vm.orderTotal.amount === null
      )?_c('v-btn',{attrs:{"x-large":"","width":"50%","loading":_vm.processing || _vm.processingPayment || _vm.paymentIsPending,"disabled":_vm.processing ||
        _vm.processingPayment ||
        !_vm.isSelectedPaymentMethodInstanceValid ||
        _vm.paymentIsPending,"color":"primary"},on:{"click":_vm.processPayment}},[_vm._v(" "+_vm._s(_vm.$t("button.apply"))+" ")]):(
        _vm.selectedPaymentMethodInstance &&
        _vm.selectedPaymentMethodInstance.namespace !== 'complete'
      )?_c('v-btn',{attrs:{"x-large":"","loading":_vm.processing || _vm.processingPayment || _vm.paymentIsPending,"width":"50%","disabled":_vm.processing ||
        _vm.processingPayment ||
        !_vm.isSelectedPaymentMethodInstanceValid ||
        _vm.paymentIsPending ||
        !_vm.allTermsAgreed,"color":"primary"},on:{"click":_vm.processPayment}},[_vm._v(" "+_vm._s(_vm.$t("Pay"))+" ")]):_vm._e()],1):_vm._e(),(_vm.checkoutStep.namespace !== 'review')?_c('div',{ref:"bottom-stepper",staticStyle:{"position":"-webkit-sticky","right":"0","bottom":"0","z-index":"50"}},[(false)?_c('CheckoutBottomStepper',{attrs:{"previousStep":_vm.previousStep,"processing":_vm.processing,"emailValid":_vm.emailValid,"nameValid":_vm.nameValid},on:{"continue":_vm.progressOrder}}):_vm._e()],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }