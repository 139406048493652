<template>
  <v-card @click="selectDeliveryService">
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon
            v-if="deliveryServiceIsSelected"
            color="success"
            size="28"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            v-else
            size="28"
          >
            mdi-checkbox-blank-circle-outline
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t(deliveryServiceTitle) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t(deliveryServiceDescription) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text class="title">
          <div v-if="deliveryServicePrice.amount > 0">
            <div v-if="!gettingDeliveryPrice">
              {{ deliveryServicePrice | formattedPrice }}
            </div>
            <div v-else>
              <v-progress-circular indeterminate/>
            </div>
          </div>
        </v-list-item-action-text>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "DeliveryService",
  props: {
    deliveryService: {
      type: Object,
      default: null
    },
    selectedDeliveryService: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      amount: null,
      currency_code: null,
      gettingDeliveryPrice: false
    }
  },
  computed: {
    deliveryServiceExists() {
      return typeof this.deliveryService !== 'undefined' && this.deliveryService !== null
    },
    deliveryServiceIsSelected() {
      if (this.deliveryServiceExists && typeof this.selectedDeliveryService !== 'undefined' && this.selectedDeliveryService !== null) {
        return this.deliveryService.uuid === this.selectedDeliveryService.uuid
      }
      return false
    },
    deliveryServiceTitle() {
      if (this.deliveryServiceExists) {
        return this.deliveryService.title
      }
      return ''
    },
    deliveryServiceDescription() {
      if (this.deliveryServiceExists) {
        return this.deliveryService.description
      }
      return ''
    },
    deliveryServicePrice() {
      return {
        amount: this.amount,
        currency_code: this.currency_code
      }
    },
  },
  methods: {
    selectDeliveryService() {
      this.$emit('selectDeliveryService', {deliveryService: this.deliveryService, deliveryServicePrice: this.deliveryServicePrice})
    },
    getDeliveryServicePrice() {
      if (this.deliveryServiceExists) {
        this.gettingDeliveryPrice = true
        this.$store.dispatch('getDeliveryServiceRateLegacyOrder', this.deliveryService).then(result => {
          if (result) {
            this.amount = result.amount
            this.currency_code = result.currency_code
          } else {
            this.amount = 0
            this.currency_code = 'ISK'
          }
        }).finally(() => {
          this.gettingDeliveryPrice = false
        })
      }
    }
  },
  mounted() {
    this.getDeliveryServicePrice()
  }
}
</script>

<style scoped>

</style>