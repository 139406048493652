<template>
	<v-card>
		<v-card flat color="grey lighten-2" style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;">
			<v-progress-linear indeterminate color="primary" height="6"></v-progress-linear>
			<v-list color="grey lighten-2">
				<v-list-item>
					<v-list-item-avatar tile rounded size="48" class="rounded">
						<v-icon v-if="!selectedPaymentMethodInstance || selectedPaymentMethodInstance.icon === null" large>mdi-information</v-icon>
						<v-img v-else large :src="selectedPaymentMethodInstance.icon"/>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>
							{{ paymentMethodInstanceTitle }} {{ $t('payment.pending') }}
						</v-list-item-title>
						<v-list-item-subtitle>
							{{ $t('payment.waitingForApproval') }}
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>
		<v-card-text class="text-center">
			<v-container fluid class="pa-0">
				<v-row dense>
					<v-col cols="12" class="title">
						{{ paymentTransactionMessage }}
					</v-col>
					<v-col cols="12" class="text--secondary">
						{{ lastCheckedText }}
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "PendingPayment",
	computed: {
		paymentResponse() {
			return this.$store.state.paymentResponse
		},
		paymentTransactionMessage() {
			return this.paymentResponse && this.paymentResponse.paymentTransaction && this.paymentResponse.paymentTransaction.message ? this.paymentResponse.paymentTransaction.message : ''
		},
		selectedPaymentMethodInstance() {
			return this.$store.getters.selectedPaymentMethodInstance
		},
		paymentMethodInstanceTitle() {
			return this.selectedPaymentMethodInstance && this.selectedPaymentMethodInstance.title ? this.selectedPaymentMethodInstance.title : this.$t('payment.general')
		},
		pendingPaymentLastCheckedDate() {
			return new Date(this.$store.state.pendingPaymentLastCheckedDate).toISOString()
		},
		lastCheckedText() {
			const date = this.pendingPaymentLastCheckedDate.substr(0, 10)
			const hour = this.pendingPaymentLastCheckedDate.substr(11, 8)
			return this.$t('payment.pendingLastChecked') + ' ' + date + ' ' + hour
		}
	}
}
</script>

<style scoped>

</style>