<template>
	<div class="checkout-section-instance">
    <h3 class="title">
      <span v-if="channelUUID === '88ea1a16-7d39-413a-94a8-48333457905f' && checkoutSectionInstance.namespace === 'receiver'">
          {{ $t('nameOnThatGoesOnShip') }}
      </span>
      <span v-else-if="channelUUID === '88ea1a16-7d39-413a-94a8-48333457905f' && checkoutSectionInstance.namespace === 'billing_minimal'">
          {{ $t('payer') }}
      </span>
      <span v-else-if="channelUUID === '84dd5086-3763-42e5-8686-1683c336c588' && checkoutSectionInstance.namespace === 'waiver'">
        {{ $t('birthday') }}
      </span>
      <span v-else>
        {{ $t(checkoutSectionInstance.title) }}
      </span>
    </h3>
    <h4
      v-if="checkoutSectionInstance.description"
      class="subtitle-1"
    >
      {{ $t(checkoutSection.description) }}
    </h4>
    <h4
      v-if="channelUUID === '58ea2964-32ac-4fe8-bb42-c646991de3f1' && checkoutSection.namespace === 'billing_minimal'"
      class="subtitle-1"
    >
      <a v-if="language === 'en'" href="https://ftsgateway.uk/registration/fotboltaland.php" target="_blank" class="red--text font-weight-bold"> {{ $t('dontForgetToCreateAUserBeforeShowingUpToTheVenue')}}</a>
      <a v-if="language === 'is'" href="https://ftsgateway.uk/registration/fotboltaland.php?lang=is" target="_blank" class="red--text font-weight-bold"> {{ $t('dontForgetToCreateAUserBeforeShowingUpToTheVenue') }}</a>
    </h4>
    <div v-if="checkoutSectionInstance.namespace === 'order_delivery' && Array.isArray(deliveryServices) && deliveryServices.length > 0">
      <v-container fluid>
        <v-row>
          <template v-for="service in deliveryServices">
            <v-col
              :key="service.uuid"
              cols="12"
              class="px-0"
            >
              <DeliveryService
                :delivery-service="service"
                @selectDeliveryService="selectDeliveryService"
                :selected-delivery-service="selectedDeliveryService"
              />
            </v-col>
          </template>
        </v-row>
      </v-container>
    </div>
    <div
      v-if="checkoutSection && checkoutSectionInstance.uiFields && checkoutSectionInstance.uiFields.length"
      class="mt-4"
    >
      <template v-for="(uiField, index) in uiFields">
        <ui-field
          :key="uiField.key"
          v-model="checkoutSectionInstance.uiFields[index]"
          :instance="checkoutSectionInstance"
          :placeholder="checkoutSectionInstanceHasPlaceholderInData"
          :hasRequired=checkoutSectionInstanceIsRequired(uiField)
          required
          v-on="$listeners"
          @billingIsShipping="billingIsShipping"
                 />
			</template>
		</div>
        <div v-if="checkoutSectionInstance.namespace === 'terms_and_conditions'">
            <v-checkbox v-model="termsAgreed">
                <template v-slot:label>
                    <div>
                        {{ $t('I have read and agree to ') }}
                        <v-tooltip bottom v-if="checkoutSectionInstance.data !== undefined && checkoutSectionInstance.data !== null && checkoutSectionInstance.data.terms_path !== undefined && checkoutSectionInstance.data.terms_path !== null">
                            <template v-slot:activator="{ on }">
                                <a  v-if="dark"
                                    target="_blank"
                                    :href="checkoutSectionInstance.data.terms_path"
                                    @click.stop
                                    v-on="on"
                                    style="color:#FFFFFFB3"
                                >
                                    {{ $t('the terms and conditions')}}.
                                </a>
                                <a  v-else
                                    target="_blank"
                                    :href="checkoutSectionInstance.data.terms_path"
                                    @click.stop
                                    v-on="on"
                                >
                                    {{ $t('the terms and conditions')}}.
                                </a>
                            </template>
                          {{ $t('Opens in new window') }}
                        </v-tooltip>
                      <template v-else>
                        {{ $t('the terms and conditions') }}.
                      </template>
                      <p v-if="organizationUUID ==='bb78d411-9b7c-4a51-a134-e359cc1bc7f8'" style="display:inline"> {{ $t('iAm20Confirmation') }}</p>
                    </div>
                </template>
            </v-checkbox>
          <div v-if="organizationUUID === '06a69a1f-8164-422e-b7d8-3753e04f6464'" style="font-size: clamp(0.7rem, 2.5vw, 0.9rem)">
            Should your payment fail due to customer authentication being required and not being set up on your card please call us on +354 5105900 so we can assist you in making a
            reservation over the phone.
          </div>
        </div>
		<div v-if="checkoutSectionInstance.namespace === 'checkout_receipt' && paymentTransaction">
			<v-btn block target="_blank" :href="'https://receipt.salescloud.is?uuid=' + order.uuid" color="primary">{{ $t('View receipt') }}</v-btn>
		</div>
		<div v-if="checkoutSectionInstance.namespace === 'payment'">
      <v-card v-if="orderTotal && orderTotal.amount === 0 && Array.isArray(dependentPayments) && dependentPayments.length > 0">
        <v-card-title>
          {{ $t('confirm') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('pleaseConfirmPayment') }}
        </v-card-subtitle>
        <v-card-text class="text--primary" style="font-size: 16px;">
          <div>
            {{ $t('aboutToPayOrderWith') }}
          </div>
          <div
            v-if="Array.isArray(dependentPayments) && dependentPayments.length > 0"
          >
            <v-list-item
              v-for="(dependentPayment, index) in dependentPayments"
              :key="'dep-' + index"
              class="transparent px-0"
              id="gift-card-totals"
            >
              <v-list-item-content>
                <v-list-item-title style="font-size: 14px;">
                  {{ $t(dependentPayment.namespace) }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="dependentPayment.details && dependentPayment.details.giftcard" style="font-size: 14px;">
                  {{ $t(dependentPayment.details.giftcard) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title class="text-right" style="font-size: 14px;">
                  {{ {amount: dependentPayment.amount, currency_code: defaultCurrencyCode} | formattedPrice }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-card-text>
      </v-card>
      <PaymentCheckoutSectionInstance
        v-else
        :checkoutSectionInstance="checkoutSectionInstance"
      />
    </div>
        <div v-if="checkoutSectionInstance.namespace === 'redirect' && redirectUrl !== null && paymentTransaction">
        </div>
	</div>
</template>

<script>

import uiField from "./uiField"
import PaymentCheckoutSectionInstance from './PaymentCheckoutSectionInstance'
import DeliveryService from "@/components/DeliveryService";

export default {
	name: 'CheckoutSectionInstance',
	props: ['checkoutSectionInstance'],
	components: {
    DeliveryService,
    uiField,
    PaymentCheckoutSectionInstance
  },
	data: () => ({
    }),
	computed: {
    language() {
      return this.$i18n.locale
    },
    defaultCurrencyCode() {
      if (typeof this.order !== 'undefined' && this.order !== null) {
        if (Array.isArray(this.order.lineItems) && this.order.lineItems.length > 0) {
          if (this.order.lineItems[0] && this.order.lineItems[0].unitPrice) {
            return this.order.lineItems[0].unitPrice.currency_code
          }
        }
      }
      return 'ISK'
    },
    orderTotal() {
      return this.$store.getters.orderTotal
    },
    dependentPayments() {
      return this.$store.state.dependentPayments
    },
    showCheckoutSection() {
      if (Array.isArray(this.deliveryServices) && this.deliveryServices.length > 0) {
        if (this.checkoutSectionIsShipping) {
          return this.selectedDeliveryService && this.selectedDeliveryService.namespace !== 'pickup'
        }
      }
      return true
    },
    selectedDeliveryService: {
      get() {
        return this.$store.state.selectedDeliveryService
      },
      set(value) {
        this.$store.commit('updateSelectedDeliveryService', value)
      }
    },
    checkoutSectionIsShipping() {
      return this.checkoutSectionInstance.namespace === 'customer_profile_shipping'
    },
    deliveryServices() {
      return this.$store.state.deliveryServices
    },
    redirectUrl() {
      if (this.checkoutSectionInstance === null || this.checkoutSectionInstance === undefined) {
        return null
      }
      if (this.checkoutSectionInstance.data === null || this.checkoutSectionInstance.data === undefined) {
        return null
      }
      if (this.checkoutSectionInstance.data.redirect_path === null || this.checkoutSectionInstance.data.redirect_path === undefined) {
        return null
      }
            return this.checkoutSectionInstance.data.redirect_path + "?uuid=" + this.order.uuid
        },
		paymentTransaction() {
			return this.paymentResponse ? this.paymentResponse.paymentTransaction : null
		},
        uiFields() {
            if(this.checkoutSectionInstance && this.checkoutSectionInstance.namespace === 'customer_profile_shipping') {
                if(this.checkoutSectionInstance.uiFields[0].value === true || this.checkoutSectionInstance.uiFields[0].value === 'true') {
                   return [this.checkoutSectionInstance.uiFields[0]]
                }
            }
            return this.checkoutSectionInstance.uiFields
        },
		paymentResponse() {
			return this.$store.state.paymentResponse
        },
        order() {
            return this.$store.state.order
        },
        paymentMethodInstances: function () {
            return this.$store.state.paymentMethodInstances
        },
        checkoutSection: function () {
            return this.$store.getters.getCheckoutSection(this.checkoutSectionInstance.namespace)
        },
        organizationUUID() {
            return this.$store.state.organizationUUID
        },
        dark() {
            return this.$vuetify.theme.dark
        },
        channelUUID() {
            return this.$store.state.channelUUID
        },
        termsAgreed: {
            get: function () {
                return this.$store.state.termsAgreedTo
            },
            set: function (newValue) {
                this.$store.commit('updateTermsAgreedTo', newValue)
            }
        },
        checkoutSectionInstanceHasPlaceholderInData: function () {
            if (this.checkoutSectionInstance && this.checkoutSectionInstance.data) {
				if (!Array.isArray(this.checkoutSectionInstance.data) && this.checkoutSectionInstance.data.placeholder) {
					return this.checkoutSectionInstance.data.placeholder
				}
			}
			return null
		},
	},
    mounted() {
      if (this.redirectUrl !== null && this.redirectUrl !== undefined) {
        window.location = this.redirectUrl
      }
      if (this.checkoutSectionInstance && this.checkoutSectionInstance.namespace === 'customer_profile_shipping') {
        if (this.checkoutSectionInstance.uiFields[0].value === true || this.checkoutSectionInstance.uiFields[0].value === 'true') {
          this.billingIsShipping(true)
        }
      }
    },
    methods: {
      checkoutSectionInstanceIsRequired(uiField) {
        if (typeof uiField !== 'undefined' && uiField !== null) {
          return uiField.required
        }
        if (this.checkoutSectionInstance && this.checkoutSectionInstance.data) {
          if (!Array.isArray(this.checkoutSectionInstance.data) && this.checkoutSectionInstance.data.required) {
            return this.checkoutSectionInstance.data.required
          }
        }
        return null
      },
      selectDeliveryService({deliveryService, deliveryServicePrice}) {
        this.selectedDeliveryService = deliveryService
        this.$store.commit('updateSelectedDeliveryServicePrice', deliveryServicePrice)
      },
      billingIsShipping(value) {
        if (this.order && value === true) {
          this.checkoutSectionInstance.uiFields.map(function (uiField) {
            uiField.required = false
          })
        }
        if (this.order && value === false) {
          this.checkoutSectionInstance.uiFields.map(function (uiField) {
            if (uiField.type !== 'checkbox') {
              uiField.required = true
                    }
                })
            }
      }
    }
};
</script>
