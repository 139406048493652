<template>
  <v-container fill-height>
    <CartSkeleton v-if="!isInitialized"/>
    <EmptyCart v-if="isInitialized && isCartEmpty">Empty cart</EmptyCart>
    <v-row v-else>
      <v-col cols="12">
        <v-simple-table>
          <thead>
          <th class="text-left"></th>
          <th class="text-left">Item</th>
            <th class="text-left">Quantity</th>
            <th class="text-right">Unit Price</th>
            <th class="text-right">Total Price</th>
          </thead>
          <tbody>
            <template v-for="(lineItem, index) in order.lineItems">
              <CartItem :key="index" :lineItem="lineItem" />
            </template>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col align="right" cols="12">
        <v-btn :disabled="!canCheckoutOrder" :loading="isProgressingOrder" @click="checkoutOutOrder" block color="primary">Continue to checkout</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import EmptyCart from "./EmptyCart";
  import CartItem from "./CartItem";
  import CartSkeleton from "./CartSkeleton";

  export default {
    name: 'CartOld',
    props: ['order'],
    components: {
      EmptyCart,
      CartItem,
      CartSkeleton
    },
    data: () => ({}),
    methods: {
      checkoutOutOrder: function() {
        const firstStep = this.$store.state.checkoutSteps[0].namespace

        this.$router.push({
          path: '/' + this.$route.params.organization + '/' + this.$route.params.channel + '/' + this.order.uuid + '/' + firstStep
        })

      }
    },
    computed: {
      isInitialized: function() {
        return this.$store.getters.isInitialized
      },
      canCheckoutOrder: function() {
        return this.$store.getters.canCheckoutOrder
      },
      isProgressingOrder: function() {
        return this.$store.state.progressingOrder
      },
      isCartEmpty: function () {
        return this.$store.getters.isCartEmpty
      }
    }
  };
</script>
