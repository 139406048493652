<template>
  <v-row>
    <v-col cols="12">
      <div class="title">Empty cart</div>
      <div class="subtitle-1">Start by adding items to your cart before continuing to checkout.</div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'EmptyCart',
  data: () => ({}),
};
</script>
