<template>
    <v-container fluid class="pa-0">
      <h2 class="mt-5 mr-5"><span>{{$t('extraService')}}</span></h2>
      <v-expansion-panels mandatory>
          <v-expansion-panel  class="my-4" v-for="(item, index) in uppsellItems" :key="index">
          <v-expansion-panel-header class="pl-0 py-0">
              <v-col v-if="item.image" cols="4" class="pa-0">
                <v-img class=""  :src="item.image"></v-img>
              </v-col>
              <v-col class="pa-0">
              <v-card outlined color="transparent">
                <v-card-title class="pb-0">{{ item.title }}</v-card-title>
                <v-card-text class="" style="font-size:medium">{{$t('priceFrom')}} <span :style="'color:' + primaryColor + '; font-weight: bold'">{{ cheapestVariationOption(item) | formattedPrice}}</span> <span>{{ $t('perPerson') }}</span></v-card-text>
              </v-card>
             </v-col>
          </v-expansion-panel-header>
          <v-divider></v-divider>
          <v-expansion-panel-content>
            <div class="pt-4">
              {{item.description}}
            </div>
            <v-list>
              <v-divider/>   
                <div v-for="(participant, index) in participants"  :key="index">
                <v-list-item >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ participant.address.name_line }}
                </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn v-if="participant && item.participants.some(person => person.id === participant.id)" icon depressed color="primary">
                      <v-icon>mdi-checkbox-marked</v-icon>  
                  </v-btn>
                  <v-btn icon v-else color="primary" @click="item.participants.push(participant)">
                    <v-icon>mdi-checkbox-blank-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider />
            </div>
              </v-list>
          </v-expansion-panel-content>
          
  
        </v-expansion-panel>
     
      </v-expansion-panels>
    </v-container>
  </template>
  
  <script>
  
  export default {
    name: "Accommodations",
    namespace: 'accommodations',
    components: {
    }, 
    data() {
      return {
        uppsellItems: [
          {
            title: 'Skemmtipakki',
            image: 'https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', 
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean pulvinar convallis lectus. Suspendisse sit amet lorem odio. Donec elementum efficitur nisi ut laoreet. Nam ut libero eget eros commodo cursus eu id felis. Mauris fermentum iaculis elementum. Vestibulum sodales aliquam libero, eget egestas arcu. Praesent nec convallis lectus. Curabitur congue scelerisque eros quis varius. Proin turpis sem, pretium vestibulum aliquam sed, pharetra nec mauris.',
            price: {
              amount: 34000, 
              currency_code: 'ISK'
            }, 
            participants: [],
          },
          {
            title: 'Forfallatrygging', 
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean pulvinar convallis lectus. Suspendisse sit amet lorem odio. Donec elementum efficitur nisi ut laoreet. Nam ut libero eget eros commodo cursus eu id felis. Mauris fermentum iaculis elementum. Vestibulum sodales aliquam libero, eget egestas arcu. Praesent nec convallis lectus. Curabitur congue scelerisque eros quis varius. Proin turpis sem, pretium vestibulum aliquam sed, pharetra nec mauris.',
            price: {
              amount: 24000, 
              currency_code: 'ISK'
            }, 
            participants: []
          }
        ],
        selectedVariation: null, 
      }
    }, 
    computed: {
      primaryColor() {
        return this.$store.getters.primaryColor
      }, 
      participants() {
        const particapants = []
        const order = this.$store.state.order
        const tripLineItems = order.lineItems.filter(lineItem => lineItem.namespace === 'trip')
      
        for(let i = 0; i < tripLineItems.length; i++) {
          if(tripLineItems[i].participants) {
            
            const participants = JSON.parse(JSON.stringify(tripLineItems[i].participants))
            for(let p = 0; p < participants.length; p++) {
              particapants.push(particapants[p])
            } 
          }
        }
        //hardcode for demonstration, need to attach participants 
        //to actual order in passenger information section
        particapants.push({address: {name_line: 'Farþegi 1'}, id: 1}, {address: {name_line: 'Farþegi 2'}, id: 2}, {address: {name_line: 'Farþegi 3'}, id: 3} )
        return particapants
      }
    },
    watch: {
    /*   uppsellItems: {
        handler(value) {
          //
          
        },
        deep: true
      } */
    },
    methods: {
      cheapestVariationOption(value) {
        const accommodation = JSON.parse(JSON.stringify(value))
        if(!accommodation || !accommodation.variations || !Array.isArray(accommodation.variations)) {
          return accommodation.price
        }
        const variations = accommodation.variations
  
        if(!variations.length > 0) {
          return accommodation.price
        }
        let currentValue = variations[0].price
        variations.forEach(function (variation) {
          if(currentValue.amount > variation.price.amount) {
            currentValue = variation.price
          }
        })
        currentValue.amount += accommodation.price.amount
        return currentValue
      }, 
      totalPrice(accommodationPrice, variationPrice) {
        if(accommodationPrice.currency_code !== variationPrice.currency_code) {
          return null
        }
        let totalPrice = {
          amount: accommodationPrice.amount + variationPrice.amount, 
          currency_code: accommodationPrice.currency_code
        }
        return totalPrice
      }
    }, 
  }
  </script>
  
  <style scoped>
  .no-padding >>> .v-expansion-panel-content__wrap {
    padding: 0;
  }
  </style>