<template>
    <v-container fluid class="pa-0">
        <h2 class="mt-5 mr-5"><span>{{$t('passengerInformation')}}</span></h2>
        <v-expansion-panels mandatory>
           <template v-for="(tripLineItem) in tripLineItems">
            <v-expansion-panel
                v-for="(participant, index) in tripLineItem.particapants"
                :key="index"
                class="my-3"
            >
                <v-expansion-panel-header>
                    <v-row>
                        <v-col cols="1">
                            <v-avatar> <v-icon large color="primary">mdi-account-outline</v-icon></v-avatar>
                        </v-col>
                        <v-col cols="10" class="pl-6 pt-5">   <span class="text-h6">{{$t('passenger')}} {{index + 1 }}</span></v-col>
                    </v-row>
                 
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-form class="mx-6">
                        <v-text-field
                            filled
                            :label="$t('firstAndMiddleName')"
                        />
                        <v-text-field
                            filled
                            :label="$t('lastName')"
                        />
                        <DateSelection
                            filled
                            noIcon
                            :label="$t('dateOfBirth')"
                        />
                        <v-radio-group
                            v-model="row"
                            row
                            >
                            <v-radio
                                :label="$t('male')"
                                value="radio-1"
                            ></v-radio>
                            <v-radio
                                :label="$t('female')"
                                value="radio-2"
                            ></v-radio>
                            <v-radio 
                                :label="$t('nonbinary/other')"
                                value="radio-3"
                            ></v-radio>
                        </v-radio-group>
                    <v-row>
                        <v-col cols="3">
                            <v-text-field
                            filled
                            :label="$t('prefix')"
                        />  
                        </v-col>
                        <v-col cols="9">
                            <v-text-field
                            filled
                            :label="$t('phoneNumber')"
                        />  
                        </v-col>
                    </v-row>
                    <v-text-field
                        filled
                        :label="$t('email')"
                    />
                    <v-text-field
                        filled
                        :label="$t('passportNumber')"
                    />
                    <v-text-field
                        filled
                        :label="$t('nationality')"
                    />
                </v-form>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </template>
        </v-expansion-panels>
    </v-container>
</template>

<script>

import DateSelection from "../common/DateSelection.vue"

export default {
    name: 'PassengerInformation', 
    namespace: 'passenger_information', 
    components: {
        DateSelection
    },
    data() {
        return {
            tripLineItems: null, 
        }
    }, 
    computed: {
        order() {
            return this.$store.state.order
        }, 
        organization() {
            return this.$store.state.organization
        }
    }, 
    mounted() {
        this.tripLineItems = this.order.lineItems.filter(lineItem => lineItem.namespace === 'trip')
        this.tripLineItems.map(lineItem => {
            lineItem.particapants = []
            for(let q = 0; q < lineItem.quantity; q++) {
                lineItem.particapants.push({
                    commerce_customer_address: {
                        country: null, 
                        name_line: null, 
                        first_name: null, 
                        last_name: null, 
                        organisation_name: null,
                        administrative_area: null, 
                        sub_administrative_area: null, 
                        locality: null, 
                        dependent_locality: null, 
                        postal_code: null, 
                        thoroughfate: null, 
                        premise: null, 
                        sub_premise: null
                    },
                    ssn: null, 
                    email: null, 
                    gender: null, 
                    birtday: null, 
                    passport: null, 
                    cancellation_insurance: null, 
                    upsell_products: null, 
                    profile_id: null, 
                    type: 'passenger', 
                    company: {}, 
                    status: '1', 
                    uid: null, 
                    user: null
               })
            }
       })
    }
}
</script>