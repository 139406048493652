<template>
  <div id="checkout">
    <v-container fill-height fluid>
      <v-row
        align="center"
        justify="center"
        class="fill-height primary"
      >
        <v-col
          v-if="!dark"
          order-md="0"
          order-sm="0"
          order="0"
          class="primary pt-0 pb-0"
          md="6"
          sm="12"
          cols="12"
        >
          <v-row
            :class="mobile ? 'pt-0 pb-0' : 'pt-0 pb-0 px-10'"
            v-if="
              organization &&
              organization.logo &&
              organization.logo.file &&
              organization.logo.file.sources &&
              organization.logo.file.sources.original &&
              inIframe
            "
          >
            <v-col class="pt-3 pb-3" cols="10">
              <!--No logo for Hvammsvík special case-->
              <div
                class="pl-4"
              >
                <v-img
                  :src="organization.logo.file.sources.original"
                  max-height="50px"
                  position="left"
                  style="max-height: 50px !important"
                  contain
                />
              </div>
            </v-col>
          </v-row>
          <CartInCheckout :class="mobile ? '' : 'px-10'" :order="order" />
        </v-col>
        <v-col
          v-if="dark"
          order-md="0"
          order-sm="0"
          order="0"
          class="pt-0 pb-0"
          :class="!mobile ? 'fill-height' : ''"
          md="6"
          sm="12"
          cols="12"
        >
          <v-row
            class="pt-0 pb-0"
            v-if="
              organization &&
              organization.logo &&
              organization.logo.file &&
              organization.logo.file.sources &&
              organization.logo.file.sources.original
            "
          >
            <v-col class="pt-0 pb-0" cols="10">
              <!--No logo for Hvammsvík special case-->
              <div
                v-if="
                  organization.uuid !== '06a69a1f-8164-422e-b7d8-3753e04f6464'
                "
              >
                <v-img
                  :src="organization.logo.file.sources.original"
                  max-height="50px"
                  position="left"
                  style="max-height: 50px !important"
                />
              </div>
            </v-col>
          </v-row>
          <CartInCheckout :order="order" />
        </v-col>
        <v-col
          :class="dark ? 'pa-0' : 'white pa-0'"
          order-md="0"
          order-sm="1"
          order="1"
          md="6"
          sm="12"
          cols="12"

        >
          <v-row :class="mobile ? '' : 'px-10'" class="ma-0" align="center">
            <v-col class="pt-0 pb-0" cols="10">
              <v-breadcrumbs class="px-1" :items="breadcrumbs">
                <template v-slot:divider>
                  <v-icon>mdi-chevron-right</v-icon>
                </template>
              </v-breadcrumbs>
            </v-col>
            <v-col class="px-4 text-end" cols="2">
              <LocaleChanger />
            </v-col>
          </v-row>
          <CheckoutForm
            id="checkout-form"
            :checkout-step="checkoutStep"
            :class="mobile ? 'pb-5' : 'px-10'"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CheckoutForm from "../components/CheckoutForm";
import CartInCheckout from "../components/CartInCheckout";
//import CheckoutCart from "@/components/cart/CheckoutCart";
//import CheckoutSteps from "@/components/CheckoutSteps";
import LocaleChanger from "../components/LocaleChanger";

export default {
  name: "checkout",
  components: {
    CheckoutForm,
    CartInCheckout,
    LocaleChanger,
  },
  computed: {
    inIframe() {
      return this.$store.state.inIframe
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 960;
    },
    dark() {
      return this.$vuetify.theme.dark;
    },
    breadcrumbs: function () {
      return this.$store.getters.getBreadcrumbs;
    },
    anonymousSession() {
      return this.$store.state.anonymousSession;
    },
    mobile: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }

      return false;
    },
    checkoutStep: function () {
      return this.$store.getters.getCheckoutStep(this.$route.params.step);
    },
    order: function () {
      return this.$store.state.order;
    },
    analyticsCheckoutObject() {
      return this.$store.getters.checkoutObject;
    },
    activeCoupon() {
      return this.$store.getters.activeCoupon;
    },
    hasGoogleAnalytics() {
      return this.$store.getters.hasGoogleAnalytics;
    },
    hasFacebookAnalytics() {
      return this.$store.getters.hasFacebookAnalytics;
    },
    fbCheckoutObject() {
      return this.$store.getters.fbCheckoutObject;
    },
    organization() {
      return this.$store.getters.organization;
    },
  },
  methods: {
    startAnalytics() {
      if (this.hasGoogleAnalytics) {
        this.$gtag.event("begin_checkout", this.analyticsCheckoutObject);
      }
      if (this.hasFacebookAnalytics) {
        window.fbq("track", "InitiateCheckout", this.fbCheckoutObject);
      }
    },
  },
  watch: {
    activeCoupon(newValue, oldValue) {
      if (newValue) {
        if (this.hasGoogleAnalytics) {
          this.$gtag.event("coupon_added", {
            event_label: "Coupon added",
          });
        }
        if (this.hasFacebookAnalytics) {
          window.fbq("trackCustom", "CouponAdded", {
            coupon: newValue.code,
          });
        }
      }
      if (!newValue && oldValue) {
        if (this.hasGoogleAnalytics) {
          this.$gtag.event("coupon_removed", {
            event_label: "Coupon removed",
          });
        }
        if (this.hasFacebookAnalytics) {
          window.fbq("trackCustom", "CouponRemoved", {
            coupon: oldValue.code,
          });
        }
      }
    },
  },
  mounted() {
    this.startAnalytics();
  },
};
</script>
