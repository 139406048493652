<template>
  <div>
    <v-switch
      v-if="optionalSwitch && !disableField"
      :ref="refValue"
      :id="refValue"
      :label="$t('fields.doYouNeedInfoOnReceipt', { field: localizedLabel })"
      :hide-details="hideDetails"
      v-model="showField"
    />

    <v-text-field
      v-if="value.type === 'number' && showField"
      type="number"
      :disabled="disableField"
      :ref="refValue"
      :id="refValue"
      filled
      :label="localizedLabel"
      :required="valueIsRequired()"
      :hint="value.placeholder"
      :hide-details="hideDetails"
      :clearable="clearable"
      v-model="value.value"
      @change="onChange"
    />

    <v-text-field
      v-if="value.type === 'text' && showField"
      type="text"
      :disabled="disableField"
      :ref="refValue"
      :id="refValue"
      :filled="filled"
      :outlined="outlined"
      :dense="dense"
      :label="localizedLabel"
      :required="valueIsRequired()"
      :hint="value.placeholder"
      :clearable="clearable"
      :loading="loading"
      :rules="[verifyText]"
      :hide-details="hideDetails"
      v-model="value.value"
      @change="onChange"
      @keyup="onKeyUp"
      @input="onChange"
    >
      <template v-slot:append>
        <v-avatar size="24" v-if="appendIconImage && instance.icon">
          <v-img :src="instance.icon"/>
        </v-avatar>
      </template>
    </v-text-field>

    <v-text-field
      v-if="value.type === 'textfield' && showField"
      type="text"
      :disabled="disableField"
      :ref="refValue"
      :id="refValue"
      filled
      :label="localizedLabel"
      :required="valueIsRequired()"
      :hint="value.placeholder"
      :clearable="clearable"
      :loading="loading"
      :hide-details="hideDetails"
      v-model="value.value"
      @change="onChange"
      @keyup="onKeyUp"
    />

    <v-textarea
      v-if="value.type === 'textarea' && showField"
      type="text"
      :disabled="disableField"
      :ref="refValue"
      :id="refValue"
      filled
      :label="localizedLabel"
      :required="valueIsRequired()"
      :hint="value.placeholder"
      :clearable="clearable"
      :hide-details="hideDetails"
      v-model="value.value"
      @change="onChange"
      @keyup="onKeyUp"
    />

    <v-text-field
      v-if="value.type === 'email' && showField"
      type="email"
      :disabled="disableField"
      :ref="refValue"
      :id="refValue"
      filled
      :label="localizedLabel"
      :required="valueIsRequired()"
      :hint="value.placeholder"
      :clearable="clearable"
      :rules="[verifyEmail]"
      :hide-details="hideDetails"
      v-model="value.value"
      @change="onChange"
    />

    <v-text-field
      v-if="value.type === 'email' && showField && isHvammsvik"
      type="email"
      :disabled="disableField"
      :ref="refValue"
      :id="refValue"
      filled
      :label="$t('confirmEmail')"
      :required="valueIsRequired()"
      :hint="value.placeholder"
      :clearable="clearable"
      :rules="[verifyDuplicateEmail]"
      :hide-details="hideDetails"
      v-model="duplicateEmail"
    />

    <v-text-field
      v-if="value.type === 'phone' && showField"
      type="phone"
      :disabled="disableField"
      :ref="refValue"
      :id="refValue"
      filled
      :label="localizedLabel"
      :required="valueIsRequired()"
      :hint="value.placeholder"
      :clearable="clearable"
      :hide-details="hideDetails"
      v-model="value.value"
      @change="onChange"
    />

    <v-checkbox
      v-if="value.type === 'checkbox'"
      :disabled="disableField"
      :ref="refValue"
      :id="refValue"
      :label="localizedLabel"
      :required="valueIsRequired()"
      :hint="value.placeholder"
      :hide-details="hideDetails"
      v-model="value.value"
      @change="onChange"
    />

    <v-switch
      v-if="value.type === 'switch'"
      :disabled="disableField"
      :ref="refValue"
      :id="refValue"
      :label="localizedLabel"
      :required="valueIsRequired()"
      :hint="value.placeholder"
      :hide-details="hideDetails"
      v-model="value.value"
      @change="onChange"
    />

    <v-select
      v-if="value.type === 'select'"
      :disabled="disableField"
      filled
      :ref="refValue"
      :id="refValue"
      :label="localizedLabel"
      :hint="value.placeholder"
      :items="value.options"
      item-text="label"
      item-value="key"
      :hide-details="hideDetails"
      v-model="value.value"
      @change="onChange"
    />

    <v-date-picker
      v-if="value.type === 'calendar'"
      :disabled="disableField"
      :ref="refValue"
      :id="refValue"
      :label="localizedLabel"
      first-day-of-week="1"
      full-width
      v-model="value.value"
      @change="onChange"
    />

    <div
      v-if="value.type === 'html'"
      v-html="value.value"
    />

    <div v-if="placeholder">
      <template v-for="(text, index) in placeholderText">
        <div :key="index" :class="index === 0 ? 'font-weight-bold': ''">
          {{ text }}
        </div>
      </template>
    </div>

    <div v-if="value.type === 'buttons'">
      <h4>{{ value.label }}</h4>
      <h5> {{ value.description }} </h5>
      <div>
        <template v-for="option in value.options">
          <v-btn :disabled="disableField" :key="option.key"> {{ option.label }}</v-btn>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'uiField',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    instance: {
      type: Object,
      default: () => {
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    hasRequired: {
      type: Boolean
    },
    clearable: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    appendIconImage: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    showField: true,
    duplicateEmail: null
  }),
  computed: {
    isHvammsvik() {
      return this.$store.state.organizationUUID === '06a69a1f-8164-422e-b7d8-3753e04f6464'
    },
    disableField() {
      if (this.loading === true) {
        return true
      }

      if (this.instance.namespace === 'billing_minimal' && this.value.key === 'name') {
        const hasSSN = this.instance.uiFields.find(uiField => uiField.key === 'ssn')
        if (hasSSN !== undefined && hasSSN.required === true) {
          return true
        }
      }

      return this.$store.state.processingPayment || this.$store.state.progressingOrder || this.disabled || false
    },
    optionalSwitch: function () {
      return this.instance.namespace === 'billing_minimal' && this.value.key === 'ssn' && this.value.required === false
    },
    localizedLabel: function () {
      let label = this.value.label
      const requiredAsterisk = this.hasRequired ? '*' : ''
      if (this.organizationUUID === 'eae77cfa-23ae-40c5-95d5-632d37541204' && this.$store.getters.orderContainsItemByUUID('b17e9ec5-8324-486c-9ffe-d9d0fe754d83')) {
        label = label.replace("Name of child", "Name of hero")
      }
      if (this.channelUUID === '88ea1a16-7d39-413a-94a8-48333457905f' && label === 'Receiver') {
        label = 'nameOnShip'
      }
      // Knattspyrnufélagið Þróttur - see Jira issue https://salescloud-devs.atlassian.net/browse/CSM-937
      if (this.organizationUUID === '1d1a8d2a-1d5b-4f87-a5c0-48776ca1f792' && label === 'Street Address') {
        return this.$t(label) + ' (nf.)' + requiredAsterisk
      }

      if (this.instance.namespace === 'yay') {
        return this.instance.namespace.toUpperCase() + ' ' + this.$t(label) + requiredAsterisk
      }

      return this.$t(label) + requiredAsterisk
    },
    refValue: function () {
      return this.instance.namespace + ':' + this.value.key
    },
    placeholderText() {
      if (!this.placeholder) {
        return ''
      }
      return this.placeholder.split('\n')
    },
    organizationUUID() {
      return this.$store.state.organizationUUID
    },
    channelUUID() {
      return this.$store.state.channelUUID
    }
  },
  methods: {
    onKeyUp() {

      if(this.value.key === 'giftcard') {
        this.value.value = this.value?.value?.toUpperCase() ?? this.value.value
      }

      if (this.value.key === 'ssn' && this.value.value !== null && this.value.value.length === 10 && this.value.required === true && (this.instance.namespace === 'billing_minimal' || this.instance.namespace === 'customer_profile_billing')) {
        this.loading = true
        this.$store.dispatch('getNameFromSSN', this.value.value).then(response => {
          if (response.success === true && response.data.found === true) {
            this.$store.commit('updateBillingName', response.data.name[0])
          }
        }).finally(() => {
          this.loading = false
        })
      } else if (this.value.key === 'ssn' && this.value.value !== null && (this.value.value.length < 10 || this.value.value.length > 10)) {
        this.$store.commit('updateBillingName', null)
      }
    },
    valueIsRequired() {
      if (this.value.required !== null && this.value.required !== undefined) {
        //hotfix for Tanit so comments can be required
        if (this.value.label === "Comment" && this.value.key === "comment" && this.$store.state.organizationUUID === '94e06cc0-471d-4864-bdcc-6cbad517d320' && this.$store.state.channel.label === 'Netsala - heimsending') {
          this.value.required = true
          //hotfix for GeoSilica so comments are required
        } else if (this.value.label === "Comment" && this.value.key === "comment" && this.$store.state.organizationUUID === 'c45a230b-0bbb-432e-a674-502589d13d74' && this.$store.state.channel.label === 'Default Channel') {
          this.value.required = true
        } else if(this.value.label === "SSN" && this.value.key === "ssn" && this.$store.state.organizationUUID === 'd0fc7c12-1780-4952-8f84-2b670913d9db' && this.$store.state.channel.label === 'Peloton netverslun') {
					this.value.required = true
				}
        return this.value.required
      }
      return false
    },
    onChange(value) {
      const copyOfValue = JSON.parse(JSON.stringify(this.value))
      copyOfValue.value = value
      this.$emit('input', copyOfValue)
    },
    verifyDuplicateEmail() {
      return this.duplicateEmail === this.value.value
    },
    verifyEmail(value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || this.$t('invalidEmail')
    },
    verifyText(value) {
      if (this.value.key === 'name') {
        const verified = value === null ? false : value.length >= this.value.minLength
        return verified || this.$t('invalidName')
      }
			if (this.value.key === 'ssn') {
				const verified = /^\d+$/.test(value) || value === null
				return verified || this.$t('ssnMustOnlyContainDigits')
			}
      return true
    },
  },
  watch: {
    value(value) {
      if(value.key === 'billing_is_shipping' && value.value === true) {
        this.$emit('billingIsShipping', true)
      }
      else if(value.key === 'billing_is_shipping' && value.value === false) {
        this.$emit('billingIsShipping', false)
      }
    },
    duplicateEmail() {
      this.$emit('duplicateEmailValidated', this.verifyDuplicateEmail())
    },
    'value.value': {
      immediate: true,
      handler(value) {
        switch (this.value.key) {
          case 'email':
            this.$emit('emailIsValid', this.verifyEmail(value))
            break
          case 'name':
            this.$emit('nameIsValid', this.verifyText(value))
            break
          default:
        }
      }
    },
    showField(value) {
      // Remove input in SSN / Kennitala field switching off optional ssn input
      if (!value && this.optionalSwitch && this.value.key === 'ssn') {
        this.value.value = null
      }
    }
  },
  created() {
    if (this.instance.namespace === 'billing_minimal' && this.value.key === 'ssn' && this.value.required === false) {
      this.showField = false
    }
  }
}
</script>
