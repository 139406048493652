<template>
  <div>
    <EmptyCart v-if="isInitialized && isCartEmpty">Empty cart</EmptyCart>
    <v-card dark flat class="transparent pb-0" v-else>
      <v-card-text class="pb-0">
        <v-list class="transparent" v-if="order && Array.isArray(order.lineItems)">
          <template v-for="(lineItem, index) in order.lineItems">
            <v-list-item class="transparent px-0" :key="lineItem.uuid">
              <v-badge offset-x="30" offset-y="20" avatar v-if="lineItem.item.images && lineItem.item.images.length > 0">
                <template v-slot:badge>
                  <div style="margin-top: 3px;">{{ lineItem.quantity }}</div>
                </template>
                <v-list-item-avatar class="ml-0" tile size="60">
                  <v-img style="border-radius: 5px;" :src="lineItem.item.images[0].file.sources.thumbnail" class="white"/>
                </v-list-item-avatar>
              </v-badge>
              <v-list-item-content>
                <v-list-item-title style="white-space: inherit;">{{ lineItemLabel(lineItem) }}</v-list-item-title>

                <v-list-item-subtitle style="white-space: inherit;" v-if="lineItem.variations !== null && lineItem.variations !== undefined && lineItem.variations.length > 0">
									<span v-for="variation in lineItem.variations" :key="variation">
										{{ variationLabel(variation) }}
									</span>
                </v-list-item-subtitle>

                <v-list-item-subtitle style="white-space: inherit;">
                  <span :class="bookingOnly ? 'font-italic' : ''">{{ quantityText(lineItem) }}</span>
                  <span v-if="lineItem.dynamicDescription !== null && lineItem.dynamicDescription !== ''"> - </span>
                  <span v-if="lineItem.dynamicDescription !== null && lineItem.dynamicDescription !== ''" v-html="lineItem.dynamicDescription"></span>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="lineItemHasBookings(lineItem)">
                  {{ $t('selectedDate') }} {{ selectedDate(lineItem.bookings) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="lineItemHasBookings(lineItem)">
                  {{ lineItem.bookings.length > 1 ? $tc('selectedHours', 2) : $tc('selectedHours', 1) }}
                  <span class="font-italic">{{ timeSlotText(lineItem.bookings) }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="text-right" v-if="!bookingOnly">
                <v-list-item-title>{{ lineItemTotal(lineItem) | formattedPrice }}</v-list-item-title>
              </v-list-item-action>
            </v-list-item>
            <v-divider in :key="index"/>
          </template>
        </v-list>
        <v-list
          v-if="selectedDeliveryService"
          class="transparent pb-0"
        >
          <v-list-item
            id="selected-delivery-service"
            class="transparent px-0"
          >

            <v-list-item-content>
              <v-list-item-title>
                {{ $t('deliveryMethod') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t(selectedDeliveryService.title) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content
              v-if="selectedDeliveryServicePrice && selectedDeliveryServicePrice.amount > 0"
              class="text-end"
            >
              <v-list-item-title>
                {{ selectedDeliveryServicePrice | formattedPrice }}
              </v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list>
        <v-divider v-if="selectedDeliveryService"/>

        <v-list class="transparent">
          <v-list-item class="transparent px-0" id="coupon" v-if="hasActiveCoupon">
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('coupon.code') }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-wrap">
                {{ activeCouponText }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-title>
                - {{ couponTotalDiscount | formattedPrice }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="transparent" v-if="!bookingOnly && location !== null && location !== undefined">
            <v-list-item-content v-if="location !== null && location !== undefined" class="text-right">
              <v-list-item-title>{{ location.label }}</v-list-item-title>
            </v-list-item-content>
            <div class="pt-2"></div>
          </v-list-item>

          <div class="pt-2" v-if="location !== null && location !== undefined && !bookingOnly"></div>

          <v-divider v-if="location !== null && location !== undefined && !bookingOnly"/>

          <div
            v-if="Array.isArray(dependentPayments) && dependentPayments.length > 0"
          >
            <v-list-item
              v-for="(dependentPayment, index) in dependentPayments"
              :key="'dep-' + index"
              class="transparent px-0"
              id="gift-card-totals"
            >
              <v-list-item-content>
                <v-list-item-title style="font-size: 14px;">
                  {{ $t('paidWith', {type: $t(dependentPayment.namespace).toLowerCase()}) }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="dependentPayment.details && dependentPayment.details.giftcard" style="font-size: 14px;">
                  {{ $t(dependentPayment.details.giftcard) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-title class="text-right" style="font-size: 14px;">
                  - {{ {amount: dependentPayment.amount, currency_code: defaultCurrencyCode} | formattedPrice }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>

          <v-divider
            v-if="Array.isArray(dependentPayments) && dependentPayments.length > 0"
            class="pb-1"
          />

          <v-list-item class="transparent px-0" id="total" v-if="!bookingOnly">

            <v-list-item-content>
              <v-list-item-title>
                {{ $t("Total") }}
                <span v-if="Array.isArray(dependentPayments) && dependentPayments.length > 0">
                  {{ $t('toBePaid').toLowerCase() }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>{{ $t("with taxes") }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-title>{{ orderTotal | formattedPrice }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>

          <v-list-item class="transparent" id="confirmation-fee" v-if="isSmarabioHopaPontun">

            <v-list-item-content>
              <v-list-item-title>{{ $t('confirmationFee') }}</v-list-item-title>
              <v-list-item-subtitle>{{ $t('paidNow') }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content class="text-right">
              <v-list-item-title>- {{ {amount: smarabioConfirmationFee, currency_code: 'ISK'} | formattedPrice }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>

          <v-list-item class="transparent" id="remainder" v-if="isSmarabioHopaPontun">

            <v-list-item-content>
              <v-list-item-title>{{ $t('remainder') }}</v-list-item-title>
              <v-list-item-subtitle>{{ $t('paidAtLocation') }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content class="text-right">
              <v-list-item-title>{{ {amount: orderTotal.amount - smarabioConfirmationFee, currency_code: 'ISK'} | formattedPrice }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>

        </v-list>

        <v-divider></v-divider>

        <v-list v-if="isSmarabioHopaPontun" class="transparent">

          <v-alert icon="mdi-information" class="transparent caption mb-0" v-if="false">
            Athugið að eingöngu er greitt staðfestingargjald á þessum tímapunkti. Eftirstöðvarnar eru gerðar upp þegar mætt er á svæðið.
          </v-alert>

          <v-list-item class="transparent" id="smarabio-confirmation-fee">

            <v-list-item-content>
              <v-list-item-title style="font-weight: 600;">
                {{ $t('toBePaid') }}
              </v-list-item-title>
              <v-list-item-subtitle style="font-weight: 500;">
                {{ $t('confirmationFee') }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content class="text-right">
              <v-list-item-title style="font-weight: 600;">
                {{ {amount: smarabioConfirmationFee, currency_code: 'ISK'} | formattedPrice }}
              </v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list>

      </v-card-text>
      <v-card-text v-if="showCouponInput || showGiftCardInput" class="px-0">
        <Coupon v-if="showCouponInput"/>
        <template v-for="instance in giftCardPaymentMethodInstances">
          <GiftCardAddToPayment :key="instance.uuid" :gift-card-payment-method-instance="instance"/>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import EmptyCart from "./EmptyCart";
import Coupon from "@/components/Coupon";
import GiftCardAddToPayment from "@/components/GiftCardAddToPayment";

/**
 * @property {Array} lineItems
 * @property {String} line_item_label
 * @property {String} dynamicDescription
 * @property {Object} unitPrice
 */

export default {
  name: 'CartInCheckout',
  props: ['order'],
  components: {
    GiftCardAddToPayment,
    Coupon,
    EmptyCart
  },
  mounted() {
    //
  },
  data: () => ({}),
  methods: {
    timeSlotText: function (bookings) {
      if (bookings && bookings.length) {
        const slotsText = bookings.map(booking => {
          return new Date(booking.startsAtTime).toLocaleTimeString().slice(0, 5)
        });

        if (slotsText.length === 1) {
          return this.$t('clock') + ' ' + slotsText[0]
        } else {
          return this.$t('clock') + ' ' + slotsText.slice(0, -1).join(', ') + ' og ' + slotsText.slice(-1)
        }
      } else {
        return null
      }

    },
    selectedDate: function (bookings) {
      let b = []
      bookings.forEach(booking => {
        b.push(booking.startsAtTime)
        b.push(booking.endsAtTime)
      })
      let fromDate = Math.min(...b)
      let toDate = Math.max(...b)

      const f = new Date(fromDate).toLocaleDateString('is-IS')
      const t = new Date(toDate).toLocaleDateString('is-IS')

      if (fromDate === toDate) {
        return f
      } else {
        return f + ' - ' + t
      }
    },
    lineItemTotal: function (lineItem) {
      if (this.$store.getters.lineItemHasCustomPrice(lineItem)) {
        return {
          amount: this.$store.getters.lineItemHasCustomPrice(lineItem),
          currency_code: lineItem.unitPrice.currency_code
        }
      } else {
        return {
          amount: lineItem.unitPrice.amount * lineItem.quantity,
          currency_code: lineItem.unitPrice.currency_code
        }
      }
    },
    checkoutOutOrder: function () {
      const firstStep = this.$store.state.checkoutSteps[0].namespace

      this.$router.push({
        path: '/' + this.$route.params.organization + '/' + this.$route.params.channel + '/' + this.order.uuid + '/' + firstStep
      })
    },
    quantityText: function (lineItem) {
      if (this.bookingOnly) {
        return this.$t('for') + ' ' + lineItem.quantity
      }

      if (lineItem.item.uuid !== 'cb8755d2-e3b6-463e-9798-79883c7af94a' || lineItem.item.uuid !== '2f0a3564-4620-45b6-a811-9fbe05fefa35') {
        return 'x' + parseInt(lineItem.quantity)
      }

      const q = parseInt(lineItem.quantity)

      switch (q) {
        case 0:
          return this.$t('quantity.none')
        case 1:
          return this.$t('quantity.some', {quantity: '1 - 4'})
        case 2:
          return this.$t('quantity.some', {quantity: '5 - 8'})
        case 3:
          return this.$t('quantity.some', {quantity: '9 - 12'})
        default:
          return this.$t('quantity.none')
      }
    },
    lineItemHasBookings: function (lineItem) {
      return this.$store.getters.lineItemHasBookings(lineItem)
    },
    lineItemLabel(lineItem) {
      if (typeof lineItem !== 'undefined' && lineItem !== null) {
        if (typeof lineItem.item !== 'undefined' && lineItem.item !== null) {
            const key = 'item:title:' + lineItem.item.uuid
            if (this.$te(key)) {
              return this.$t(key)
            }
            return lineItem.item.title
        }
        if (this.language !== 'is') {
          return lineItem.line_item_label.replace('Miði að ', '')
        }
        if (this.$route.params.organization === 'b5529bcd-f2cc-4423-88af-71603c8ed175') { //Nova hardcode
          return lineItem.line_item_label.replace('Miði að ', 'Miði á ')
        }
        return lineItem.line_item_label
      }
      return ''
    },
    variationLabel(variation) {
      if (typeof variation !== 'undefined' && variation !== null) {
        const key = 'variation:label:' + variation.uuid
        if (this.$te(key)) {
          return this.$t(key)
        }
        return variation.label
      }
      return ''
    }
  },
  computed: {
    organization() {
      return this.$store.state.organization
    },
    isAllowedDependentPayments() {
      if (typeof this.organization !== 'undefined' && this.organization !== null) {
        return (this.organization.uuid === this.$store.state.hvammsvik || this.organization.uuid === '32036a02-fd37-4044-bbb1-e55970e4531f')
      }
      return false
    },
    defaultCurrencyCode() {
      if (typeof this.order !== 'undefined' && this.order !== null) {
        if (Array.isArray(this.order.lineItems) && this.order.lineItems.length > 0) {
          if (this.order.lineItems[0] && this.order.lineItems[0].unitPrice) {
            return this.order.lineItems[0].unitPrice.currency_code
          }
        }
      }
      return 'ISK'
    },
    dependentPayments() {
      return this.$store.state.dependentPayments
    },
    paymentMethodInstances() {
      return this.$store.state.paymentMethodInstances
    },
    namespacesWhitelistedForDependentPayment() {
      return this.$store.state.namespacesWhitelistedForDependentPayment
    },
    giftCardPaymentMethodInstances() {
      if (this.isAllowedDependentPayments && Array.isArray(this.paymentMethodInstances) && this.paymentMethodInstances.length > 0) {
        return this.paymentMethodInstances.filter(instance => instance && instance.namespace && this.namespacesWhitelistedForDependentPayment.includes(instance.namespace))
      }
      return []
    },
    selectedDeliveryService() {
      return this.$store.state.selectedDeliveryService
    },
    selectedDeliveryServicePrice() {
      return this.$store.state.selectedDeliveryServicePrice
    },
    location() {
      return this.$store.getters.location
    },
    isSmarabioHopaPontun() {
      return this.$store.getters.isSmarabioHopaPontun
    },
    smarabioConfirmationFee() {
      let firstOrderLineItem = null
      if (typeof this.order !== 'undefined' && this.order !== null) {
        if (Array.isArray(this.order.lineItems) && this.order.lineItems.length > 0) {
          firstOrderLineItem = this.order.lineItems[0]
        }
      }
      if (firstOrderLineItem) {
        return this.$store.getters.smarabioConfirmationFee(firstOrderLineItem.quantity)
      }
      return this.$store.getters.smarabioConfirmationFee()
    },
    language() {
      return this.$i18n.locale
    },
    bookingOnly() {
      return this.$store.getters.bookingOnly
    },
    selectedPaymentMethodInstance: function () {
      return this.$store.getters.selectedPaymentMethodInstance
    },
    showGiftCardInput() {
      if (this.$route.params.step === null || this.$route.params.step === undefined) {
        return false
      }

      return this.giftCardPaymentMethodInstances.length > 0 && this.$store.getters.getCheckoutStep(this.$route.params.step).namespace !== 'checkout' && this.$store.getters.getCheckoutStep(this.$route.params.step).namespace !== 'complete'
    },
    showCouponInput() {

      if (this.$route.params.step === null || this.$route.params.step === undefined) {
        return false
      }

      return this.$store.getters.showCouponInput && this.$store.getters.getCheckoutStep(this.$route.params.step).namespace !== 'checkout' && this.$store.getters.getCheckoutStep(this.$route.params.step).namespace !== 'complete'
    },
    hasActiveCoupon: function () {
      return this.$store.getters.hasActiveCoupon
    },
    activeCouponText: function () {
      return this.$store.getters.activeCouponText
    },
    couponTotalDiscount: function () {
      return this.$store.getters.couponTotalDiscount
    },
    showCheckoutAvatar: function () {
      return this.$store.state.showCheckoutAvatar
    },
    selectedTimeSlots: function () {
      return this.$store.state.selectedTimeSlots
    },
    orderTotal: function () {
      return this.$store.getters.orderTotal
    },
    isInitialized: function () {
      return this.$store.getters.isInitialized
    },
    canCheckoutOrder: function () {
      return this.$store.getters.canCheckoutOrder
    },
    isProgressingOrder: function () {
      return this.$store.state.progressingOrder
    },
    isCartEmpty: function () {
      return this.$store.getters.isCartEmpty
    }
  }
};
</script>
